@import 'variables';

.personalized {
    //- reset letter spacing
    letter-spacing: unset;

    [class^="container"] {
        position: relative;
        display: flex;
    }

    &__content {
        padding: calc($grid-gutter-width / 2);
        font-size: map-deep-get($personalizedContent, schema, fontSize);
        color: map-deep-get($personalizedContent, schema, contentColor);
        text-align: left;
        border-radius: 4px;

        @include media('<mobile') {
            --personalized-fs: 16px;
        }
    }

    &--bubble {
        .personalized {
            &__content {
                position: relative;
                width: auto;
                margin-right: 20%;
                margin-bottom: 12.5%;
                background-color: map-deep-get($personalizedContent, schema, contentBg);
                box-shadow: map-deep-get($personalizedContent, schema, boxShadow);
            }
        
            &__bubbleArrow {
                overflow: hidden;
                position: absolute;
                top: 1.14em;
                left: 100%;
                width: 1em;
                height: 100%;
                font-size: 40px;
                transform: skew(0deg, 65deg);
        
                &::after {
                    content: '';
                    position: absolute;
                    left: -0.55em;
                    bottom: 68%;
                    width: 0;
                    height: 0;
                    border-width: 0 .75em .75em 0;
                    border-style: solid;
                    border-color: transparent transparent map-deep-get($personalizedContent, schema, contentBg) transparent;
                    box-shadow: map-deep-get($personalizedContent, schema, boxShadow);
                    transform: rotate(-145deg)
                }
            }
        
            &__img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: auto;
            }
        }
    }

    &--expand {
        margin-top: map-get($section-inner, mobile);

        .personalized {
            [class^="container"] {
                flex-direction: column;
            }

            &__wrap {
                width: 100%;
                border: 1px solid $mainColorLight;
                border-radius: 4px;
                overflow: hidden;
            }

            &__title {
                padding: 0.5em 0.89em;
                width: 100%;
                background: $mainColorLight;
                color: $white;
                text-align: center;
                font-size: 18px;
                font-family: var(--mainFont);;
                font-weight: 600;
                line-height: 1.5;

                @include media ("<mobile") {
                    font-size: 16px;
                }

                &:not(.collapsed) + .personalized__content {
                    padding: 15px;
                }

                span {
                    background: $white;
                    color: $mainColorLight;
                    border-radius: 50%;
                    width: 24px;
                    display: inline-block;
                    line-height: 1.334;
                    margin-left: 5px;
                }
            }

            &__content {
                transition: 0.3s;
                padding: 0 15px;
            }


        }
    }
}