$scrollbar-color: #2c507c;
$scrollbar-bg: #fff;

//- https://css-tricks.com/almanac/properties/s/scrollbar/
//- example: https://codepen.io/devstreak/pen/dMYgeO
body {
    &, &.modal-open .modal {
        &::-webkit-scrollbar {
            width: 8px;
            background-color: $scrollbar-bg;
        }
    
        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background-color: $scrollbar-bg;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: $scrollbar-color; 
        }
    }

    &.modal-open, .modal {
        padding-right: 8px!important;
    }
}
