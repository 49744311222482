//- Motion related animations
//- Jump
@keyframes jump{
    0%   { transform: translateY(0); }
    60%  { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}
@-webkit-keyframes jump{
    0%   { transform: translateY(0); }
    60%  { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}
@-moz-keyframes jump{
    0%   { transform: translateY(0); }
    60%  { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}

//- Weight
@keyframes weight{
    0%   { transform: rotate(-48deg); }
    100% { transform: rotate(48deg); }
}
@-webkit-keyframes weight{
    0%   { transform: rotate(-48deg); }
    100% { transform: rotate(48deg); }
}
@-moz-keyframes weight{
    0%   { transform: rotate(-48deg); }
    100% { transform: rotate(48deg); }
}

//- Hammer
@keyframes hammer{
    0%   { transform: rotate(28deg); }
    100% { transform: rotate(2deg); }
}
@-webkit-keyframes hammer{
    0%   { transform: rotate(28deg); }
    100% { transform: rotate(2deg); }
}
@-moz-keyframes hammer{
    0%   { transform: rotate(28deg); }
    100% { transform: rotate(2deg); }
}

//- Scale
@keyframes scale{
    0%   { transform: scale(1); }
    70%  { transform: scale(2); }
    100% { transform: scale(1); }
}
@-webkit-keyframes scale{
    0%   { transform: scale(1); }
    70%  { transform: scale(2); }
    100% { transform: scale(1); }
}
@-moz-keyframes scale{
    0%   { transform: scale(1); }
    70%  { transform: scale(2); }
    100% { transform: scale(1); }
}

//- Scale appear
@keyframes scaleAppear{
    0%   { transform: scale(0); }
    70%  { transform: scale(1.25); }
    100% { transform: scale(1); }
}
@-webkit-keyframes scaleAppear{
    0%   { transform: scale(0); }
    70%  { transform: scale(1.25); }
    100% { transform: scale(1); }
}
@-moz-keyframes scaleAppear{
    0%   { transform: scale(0); }
    70%  { transform: scale(1.25); }
    100% { transform: scale(1); }
}

//- Hoop
@keyframes hoop{
    0%   { transform: translateX(0px); }
    30%  { transform: translateX(-40px); }
    40%  { transform: translateX(0px); }
    50%  { transform: translateX(50px); }
    100% { transform: translateX(0px); }
}
@-webkit-keyframes hoop{
    0%   { transform: translateX(0px); }
    30%  { transform: translateX(-40px); }
    40%  { transform: translateX(0px); }
    50%  { transform: translateX(50px); }
    100% { transform: translateX(0px); }
}
@-moz-keyframes hoop{
    0%   { transform: translateX(0px); }
    30%  { transform: translateX(-40px); }
    40%  { transform: translateX(0px); }
    50%  { transform: translateX(50px); }
    100% { transform: translateX(0px); }
}

//- Danse
@keyframes danse{
    0%   { transform: translateX(0px); }   
    50%  { transform: translateX(-10px); }
    70%  { transform: translateX(10px); }
    100% { transform: translateX(0px); }
}
@-webkit-keyframes danse{
    0%   { transform: translateX(0px); }   
    50%  { transform: translateX(-10px); }
    70%  { transform: translateX(10px); }
    100% { transform: translateX(0px); }
}
@-moz-keyframes danse{
    0%   { transform: translateX(0px); }   
    50%  { transform: translateX(-10px); }
    70%  { transform: translateX(10px); }
    100% { transform: translateX(0px); }
}

//- Hamburger
@keyframes hamburger{
    0%   { transform: scale(1); }
    100% { transform: scale(0); }
}
@-webkit-keyframes hamburger{
    0%   { transform: scale(1); }
    100% { transform: scale(0); }
}
@-moz-keyframes hamburger{
    0%   { transform: scale(1); }
    100% { transform: scale(0); }
}

//- Bottle Dance
@keyframes bottleDance{
    0%   { transform: rotate(0deg); }
    5%   { transform: rotate(5deg); }
    95%  { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}
@-webkit-keyframes bottleDance{
    0%   { transform: rotate(0deg); }
    5%   { transform: rotate(5deg); }
    95%  { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}
@-moz-keyframes bottleDance{
    0%   { transform: rotate(0deg); }
    5%   { transform: rotate(5deg); }
    95%  { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
}

//- Bounce
@keyframes bounce {
    0%   { transform: translateY(0); }
    10%  { transform: translateY(0); }
    30%  { transform: translateY(-25px); }
    40%  { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    60%  { transform: translateY(0); }
    100% { transform: translateY(0); }
}
@-webkit-keyframes bounce {
    0%   { transform: translateY(0); }
    10%  { transform: translateY(0); }
    30%  { transform: translateY(-25px); }
    40%  { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    60%  { transform: translateY(0); }
    100% { transform: translateY(0); }
}
@-moz-keyframes bounce {
    0%   { transform: translateY(0); }
    10%  { transform: translateY(0); }
    30%  { transform: translateY(-25px); }
    40%  { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    60%  { transform: translateY(0); }
    100% { transform: translateY(0); }
}

//- Bounce with distortion
@keyframes bounce-with-distortion {
    0%   { transform: scale(1,1)       translateY(0); }
    10%  { transform: scale(1.03,.97)  translateY(0); }
    30%  { transform: scale(.97,1.03)  translateY(-25px); }
    40%  { transform: scale(1.03,.97)  translateY(0); }
    50%  { transform: scale(1,1)       translateY(-10px); }
    60%  { transform: scale(1,1)       translateY(0); }
    100% { transform: scale(1,1)       translateY(0); }
}
@-webkit-keyframes bounce-with-distortion {
    0%   { transform: scale(1,1)       translateY(0); }
    10%  { transform: scale(1.03,.97)  translateY(0); }
    30%  { transform: scale(.97,1.03)  translateY(-25px); }
    40%  { transform: scale(1.03,.97)  translateY(0); }
    50%  { transform: scale(1,1)       translateY(-10px); }
    60%  { transform: scale(1,1)       translateY(0); }
    100% { transform: scale(1,1)       translateY(0); }
}
@-moz-keyframes bounce-with-distortion {
    0%   { transform: scale(1,1)       translateY(0); }
    10%  { transform: scale(1.03,.97)  translateY(0); }
    30%  { transform: scale(.97,1.03)  translateY(-25px); }
    40%  { transform: scale(1.03,.97)  translateY(0); }
    50%  { transform: scale(1,1)       translateY(-10px); }
    60%  { transform: scale(1,1)       translateY(0); }
    100% { transform: scale(1,1)       translateY(0); }
}

@keyframes translateYAndScale {
    0% {
        transform: translateY(-50%) scale(1);
    }
    50% {
        transform: translateY(-50%) scale(1.5);
    }
    80% {
        transform: translateY(-50%) scale(1);
    }
    100% {
        transform: translateY(-50%) scale(1);
    }
}
@-webkit-keyframes translateYAndScale {
    0% {
        transform: translateY(-50%) scale(1);
    }
    50% {
        transform: translateY(-50%) scale(1.5);
    }
    80% {
        transform: translateY(-50%) scale(1);
    }
    100% {
        transform: translateY(-50%) scale(1);
    }
}

@-moz-keyframes translateYAndScale {
    0% {
        transform: translateY(-50%) scale(1);
    }
    50% {
        transform: translateY(-50%) scale(1.5);
    }
    80% {
        transform: translateY(-50%) scale(1);
    }
    100% {
        transform: translateY(-50%) scale(1);
    }
}

@keyframes translateXAndScale {
    0% {
        transform: translateX(-50%) scale(1);
    }
    50% {
        transform: translateX(-50%) scale(1.5);
    }
    80% {
        transform: translateX(-50%) scale(1);
    }
    100% {
        transform: translateX(-50%) scale(1);
    }
}
@-webkit-keyframes translateXAndScale {
    0% {
        transform: translateX(-50%) scale(1);
    }
    50% {
        transform: translateX(-50%) scale(1.5);
    }
    80% {
        transform: translateX(-50%) scale(1);
    }
    100% {
        transform: translateX(-50%) scale(1);
    }
}

@-moz-keyframes translateXAndScale {
    0% {
        transform: translateX(-50%) scale(1);
    }
    50% {
        transform: translateX(-50%) scale(1.5);
    }
    80% {
        transform: translateX(-50%) scale(1);
    }
    100% {
        transform: translateX(-50%) scale(1);
    }
}

//- Slide From Top - Time Sensitive Banner
@keyframes slideFromTop {
    0% {
        top: -100%;
    }
    100% {
        top: 0;
    }
}
@-webkit-keyframes slideFromTop {
    0% {
        top: -100%;
    }
    100% {
        top: 0;
    }
}
@-moz-keyframes slideFromTop {
    0% {
        top: -100%;
    }
    100% {
        top: 0;
    }
}