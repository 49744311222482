@import 'variables';

.btn { 
    display: inline-block; width: auto;
    min-width: map-get($main-cta-structure, min-width); margin: 0 auto; padding: map-get($main-cta-structure, block-padding);    
    font-size: map-deep-get($main-cta-structure, font, size); font-weight: map-deep-get($main-cta-structure, font, weight); line-height: map-deep-get($main-cta-structure, font, line-height); letter-spacing: normal; color: map-get($main-cta-schema, color); background: map-get($main-cta-schema, bg); background: map-get($main-cta-schema, gradient-bg); box-shadow: map-get($main-cta-schema, shadow); border-radius: map-get($main-cta-structure, radius);
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        background: map-get($main-cta-schema, bg); background: map-get($main-cta-schema, gradient-bg); box-shadow: map-get($main-cta-schema, shadow);
    }
    .icon {
        flex: 0 0 auto;
        margin-left: map-get($section-flow, mobile) / 2; margin-top: -2px;
        font-size: map-deep-get($main-cta-structure, font, icon); color: map-get($main-cta-schema, icon); stroke: currentColor;
    }

    &.disabled {
        color: map-get($main-cta-schema, color-disabled); background: map-get($main-cta-schema, background-disabled); pointer-events: none;
    }
   

    &--wider {
        width: 75%;

        @include media('<tablet') {
            width: 90%;
        }
    }

    &--v01 {
        width: 100%;
        max-width: 270px;
        padding-block: .25em;
        font-weight: normal;
        background-image: 
        linear-gradient(to bottom, $ctaLight, $ctaLight2);
        border-radius: 14px;
        box-shadow: none;
        
        @include media('<mobile') {
            font-size: 16px;
        }
        
        @include media('>=mobile') {
            font-size: 20px;
        }
    }
}

.cta-wrap{
    width: 100%;
 }
