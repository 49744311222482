@import 'variables';

:root {
    --tickerBottomPos: 15px;
    --tickerTopPos: 70px;
}

.ticker {
    position: fixed;  
    display: flex; align-items: center;
    width: 200px; padding: map-get($ticker, inner-space) #{map-get($ticker, inner-space) * 2};
    font-family: map-get($ticker, font);
    background-color: map-get($ticker, bg); border-radius: map-get($ticker, radius); box-shadow: map-deep-get($ticker, shadow, values) map-deep-get($ticker, shadow, color); 
    transition: map-get($ticker, transition); 
    z-index: 3;

    &__text {
        font-size: map-get($ticker, font-size); font-weight: map-get($ticker, font-weight); text-align: center; line-height: 1.4; color: $white;

        body.v02 & {
            margin-block: 0; margin-inline-start: map-get($ticker, inner-space);
        }
    }

    &__image {
        width: auto; height: 100%; max-height: 70px; margin-inline: map-get($ticker, inner-space);
    }

    &--bottomLeft {
        left: map-get($ticker, left); bottom: var(--tickerBottomPos);
        transform: translateY(200px);

        &.anim-in {
            transform: translateY(0);
        }
        
    }

    &--topLeft {
        left: map-get($ticker, left); top: var(--tickerTopPos);
        transform: translateX(-250px);

        &.anim-in {
            transform: translateX(0);
        }
        
    }
}
