@import 'variables';

.faq { 
    margin-top: 0; padding-bottom: 0;
    
    &__title { 
        position: relative; margin-bottom: 1.5rem; padding-bottom: 2.3rem;

        &::after {
            content: '';
            position: absolute; bottom: 0; left: 50%; width: 5rem; height: .3rem; transform: translateX(-50%);
            background-color: $highlight-color;
        }
    }
}

.accordion {
    &__list {
        position: relative;

        & + & {
            border-top: $faq-border-with solid $highlight-color;
        }
    }

    &__question {
        display: flex; justify-content: space-between; align-items: baseline;
        padding: map-get($section-inner, mobile) .5rem; color: $accordion-title; letter-spacing: .1rem;

        &::before {
            content: ''; position: absolute; top: -#{$faq-border-with}; bottom: -#{$faq-border-with}; left: -#{$grid-gutter-width / 2}; display: block; width: calc(100% + #{$grid-gutter-width}); z-index: -1;
        }

        &:active {
            &::before {
                animation: bgColor-on-tap .75s ease-in-out;
            }
        }
    }

    &__answer {
        padding: 0 .5rem map-get($section-inner, mobile);

        p {
            & + ul {
                margin-top: map-get($section-flow, mobile) / 2;
            }
        }

        ul {
            padding-left: 2rem;
            list-style: disc;
        }
    }

    .icon--collapse {
        flex: 0 0 auto; margin-left: .5rem;
        font-size: 1rem; stroke: currentColor;
    }
}

