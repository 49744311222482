@import "variables";


.cart--MultipleProductsSlider {
    overflow: hidden; 
    position: relative; 
    padding-block: 2.5rem;
    text-align: center;
    letter-spacing: 0;
    background-color: transparent;
    background-repeat: no-repeat;

    @include media('<tablet') {
        background: {
            image: var(--header_left_bg_m), var(--header_right_bg_m);
            size: 45%, 35%; 
            position: -31vw -8vh, 79vw 28vh;
        }
    }

    @include media('>=tablet') {
        background: {
            image:  var(--header_left_bg_d), var(--header_right_bg_d);
        }
    }

    @include media('>=tablet', '<desktop') {
        @include media('portrait') {
            background: {
                size: auto 75%;
                position: -10vw 3vh, 82vw 3vh;
            }
        }

        @include media('landscape') {
            background: {
                size: auto 65%;
                position: -8vw 8vh, 88vw 12vh;
            }
        }
    }

    @include media('>=desktop') {
        background: {
            size: auto 75%;
            position: -5vw 80px, 91vw 100px;
        }
    }

    & > * {
        position: relative;
    }
    
    &::before,
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &::before {
        height: 100%;
        background: {
            repeat: no-repeat;
            position: center top;
            size: 100% auto;
            attachment: fixed;
        };
        z-index: -1;

        @include media('<tablet') {
            background-image: var(--header_bg_m);
        }
        
        @include media('>=tablet') {
            background-image: var(--header_bg_d);
        }
    }

    &::after {
        height: 80%;
        background-image: linear-gradient(to bottom,rgba(69,81,109,0),rgba(69,81,109,.6));
        z-index: -1;
    }

    .slick-dotted {
        &.slick-slider {
            margin-bottom: 40px;
        }
    }

    .slick-track {
        margin-bottom: 8px;
    }

    .slick-dots {
        li {
            width: 1.15em; height: 1.15em;
            margin: 0 4px;

            button {
                &:before {
                    opacity: 1;
                    background-color: $white;
                }
            }

            &.slick-active {
                button {
                    &:before {
                        background-color: $mainColorLight;
                    }
                }
            }
        }
    }

    .benefits {
        margin-bottom: 40px;
    }

    .cart {

        &[data-check="un-subscribe"] {
            @include media('<tablet') {
                // padding-bottom: calc((#{map-deep-get($purchase-mode, height, mobile)} + #{map-deep-get($purchase-mode, border, size)}) * 2 / 2);
            }

            @include media('>=tablet') {
                // padding-bottom: #{map-get($section-inner, desktop)} / 2 - #{map-deep-get($purchase-mode, border, size)};
            }
        }

        &__mainTitle {
            margin-bottom: 1.25em;
            font-size: 20px;
            font-weight: $font-weight-bold;
            letter-spacing: normal; 
            color: $mainColorDark;
        }

        &__wrapper {
            @include media('<tablet') {
                padding-top: map-get($section-inner, mobile); padding-bottom: calc(#{map-get($section-inner, mobile)} + #{map-deep-get($purchase-mode, border, size)} * 2);
                
                @include media('portrait') {
                    
                }

                @include media('landscape') {
                    
                }
            }

            @include media('>=tablet') {
                padding-top: map-get($section-inner, desktop); padding-bottom: calc(#{map-get($section-inner, desktop)} + #{map-deep-get($purchase-mode, height, desktop)} + #{map-deep-get($purchase-mode, border, size)} * 2);
            }

            @include media('>=tablet', '<desktop') {
                @include media('portrait') {
                    
                }

                @include media('landscape') {
                    
                }
            }

            @include media('>=desktop') {
                
            }
        }

        &__container {
            @include media('<tablet-mid') {
                padding-right: 0; padding-left: 0;
            }

            @include media('>=tablet-mid', '<desktop') {
                padding-right: 20px; padding-left: 20px;
            }

            @include media('>=desktop') {
                padding-right: 50px; padding-left: 50px;
            }
        }

        &__title {
            margin-bottom: 20px;
            color: map-deep-get($cart, title, color);
        }

        &__cards {
            display: flex;
            color: $text-color;

            @include media('>=tablet') {
                margin-bottom: 1em;
            }
        }
        
    }
    &.cart--sliderNoImages .amount {
        margin-top: 20px;
    }    
}

.card {
    position: relative; 
    display: flex; flex-direction: column; flex: 1 0 30%;
    width: calc(33.33% - 10px);
    padding: #{map-get($card, inner-space) * 2} #{map-get($card, inner-space) * 1.5} #{map-get($card, inner-space) * 3};
    background-image: linear-gradient(to top,rgb(136, 147, 187) 0%, rgb(157, 164, 188) 25%, rgb(255, 255, 255) 50%);
    border: 0; border-radius: 3px;
    box-shadow: map-deep-get($card, shadow, default);
    
    &::before {
        content: '';
        position: absolute; z-index: 0;
        top: 1px; left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-image: linear-gradient(to bottom,rgb(136, 147, 187) 0%, rgb(157, 164, 188) 25%, rgb(255, 255, 255) 50%);
        border-radius: 3px;
    }
    
    & > * {
        position: relative;
        z-index: 1;
    }
    
    @include media('<mobile') {
        font-size: map-get($card, default-size) - 2;
    }

    @include media('>=mobile') {
        font-size: map-get($card, default-size);
    }

    @include media('<tablet') {
        margin-right: map-deep-get($card, outer-space, mobile) / 4; margin-left: map-deep-get($card, outer-space, mobile) / 4;
    }

    @include media('>=tablet') {
        padding-bottom: #{map-get($card, inner-space) * 4};
    }

    @include media('>=tablet', '<desktop') {
        & + & {
            margin-left: map-deep-get($card, outer-space, tablet);
        }
    }

    @include media('>=desktop') {
        & + & {
            margin-left: map-deep-get($card, outer-space, desktop);
        }
    }

    &__title {
        font-weight: $font-weight-bold;
        color: $white;
        font-size: map-get($card, title);
    }

    .amount {
        margin-bottom: .3em;
        color: $mainColorLight;
        font-weight: $font-weight-bolder;
        
        @include media('<mobile') {
            font-size: map-get($card, amount) - 16;

            .cart--nonUS & {
                font-size: map-get($card, amount) - 20px;
            }
        }
        
        @include media('>=mobile') {
            font-size: map-get($card, amount);
            
            .cart--nonUS & {
                font-size: map-get($card, amount) - 10px;
            }
        }

        &__country {
            font-size: .75em;
        }

        &__periodicity {
            display: none;
            font-size: .66em; font-weight: normal;
        }
    }

    .retail, .save, .shipping, .purchaseMode {
        line-height: 1.6;
    }

    .retail {
        color: #FF0000;

        &__value, &__currency {
            text-decoration: line-through;
        }
    }

    .save {
        color: #F69220;
    }

    .shipping {
        margin-bottom: .5em;
        font-size: 18px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $mainColor;
    }
}

.stamp {
    position: absolute;
    display: inline-block;   
    font-weight: normal;
    color: $white;    
    line-height: 1.3;         

    svg {
        .card--three & {
            fill: map-deep-get($stamp, color, seller);
        }
        
        .card--six & {
            fill: map-deep-get($stamp, color, value);
        }
    }

    &__value {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--best {
        font-size: 16px;

        @include media('<mobile') {
            top: -18%;
            right: -18%;
        }

        @include media('>=mobile', '<tablet-mid') {
            top: -10%;
            right: -12%;
        }

        @include media('<tablet-mid') {
            transform: rotate(341deg) scale(.575);
        }

        @include media('>=tablet-mid') {
            transform: rotate(341deg) scale(.675);
            top: -10%;
            right: -10%;
        }

        .cart--sliderNoImages & {

            @include media('<mobile') {
                top: -60px;
                right: -18%;
            }

            @include media('>=mobile') {
                margin-top: -60px;
            }

            @include media('>=mobile', '<tablet-mid') {
                right: -30px;
            }
        }

    }

    &--dr {
        font-size: 12px;

        @include media('<mobile') {
            top: -20%;
            left: -14%;
        }

        @include media('>=mobile', '<tablet-mid') {
            top: -14%;
            left: -8%;
        }

        @include media('<tablet-mid') {
            transform: rotate(341deg) scale(.8);
        }

        @include media('>=tablet-mid') {
            transform: rotate(341deg) scale(.9);
            top: -8%;
            left: -5%;
        }

        .cart--sliderNoImages & {
            @include media('<mobile') {
                left: -45px;
                top: -10px;
            }
    
            @include media('>=mobile', '<tablet') {
                top: 80px;
            }
    
            @include media('>=mobile', '<mobile-mid') {
                left: -16%;
            }

            @include media('>=mobile-mid', '<mobile-max') {
                left: -8%;
            }

            @include media('>=mobile-max', '<tablet') {
                left: -8%;
            }

            @include media('<tablet-mid') {
                transform: rotate(341deg) scale(.7);
            }
    
            @include media('>=tablet') {
                top: 70px;
            }

            @include media('>=tablet', '<tablet-mid') {
                left: -8%;
            }

            @include media('>=tablet-mid') {
                left: -50px;
            }

            @media (min-width: 1200px) {
                left: -23px;
            }

            @include media('>=desktop-mid') {
                left: -23px;
            }
        }
    }
} 

.purchaseMode {
    &__label {
        @include user-select;
        display: flex; align-items: center;

        .purchaseMode--card & {
            justify-content: center; 
            color: $mainColorLight;

            @include media('<mobile') {
                font-size: 14px;
            }

            @include media('>=tablet', '<tablet-mid') {
                font-size: 14px;
            }
        }

        .purchaseMode--disclaimer & {
            justify-content: flex-start; 
            color: $mainColorLight;
            font-weight: bold;

            @include media('<mobile') {
                font-size: 13px;
            }

            @include media('<tablet') {
                height: map-deep-get($purchase-mode, height, mobile);
            }

            @include media('>=tablet') {
                height: map-deep-get($purchase-mode, height, desktop);
            }
        }

        @include media('hover', 'fine') {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__input {
        opacity: 0;
        position: absolute; left: -100%;
        visibility: hidden;
    }

    &__checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.35em; height: 1.35em;
        font-size: 1em;
        margin-right: 5px;
        background-color: $white;
        border: 1px solid; border-radius: 4px;
        border-color: currentColor;

        .purchaseMode--disclaimer & {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
    }

    &__disclaimer {
        display: none;
        margin-top: 10px; 
        font-size: 12px;
        text-align: left;
        color: $white;

        .cart[data-check="subscribe"] & {
            display: block;
        }
    }

    &__list {
        display: flex; 
        font-weight: $font-weight-bold; text-align: left;

        @include media('<tablet') {
            flex-wrap: wrap; 
            margin-block-end: 20px;
        }

        @include media('>=tablet') {
            flex-wrap: nowrap; justify-content: space-between;
            margin-block-end: 30px;
        }
    }
    
    &__item {
        font-weight: $font-weight-bold;

        @include media('<tablet') {
            flex: 1 1 50%;
            font-size: 16px; text-align: left;
        }

        @include media('>=tablet') {
            flex: 1 1 auto; 
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }

        @include media('>=tablet', '<desktop') {
            font-size: 18px;
        }

        @include media('>=desktop') {
            font-size: 20px;
        }
    }

    &--card {
        margin-bottom: 15px;

        .icon {
            color: var(--mainColor);
        }
    }

    &--disclaimer {
        position: relative; 
        max-width: #{map-get($container-max-widths, lg) - $grid-gutter-width}; padding-right: $grid-gutter-width / 2; padding-left: $grid-gutter-width / 2;
        color: map-get($purchase-mode, color);

        @include media('<tablet') {
            margin-top: -#{map-get($section-inner, mobile)};
        }

        @include media('>=tablet') {
            margin-top: calc(-#{map-get($section-inner, desktop)} / 2 + #{map-deep-get($purchase-mode, height, desktop)} + #{map-deep-get($purchase-mode, border, size)});
        }
    }
}

.icon {
    &--checkbox {
        display: none;
        color: currentColor;
        fill: currentColor;
        stroke: currentColor;

        #orderNow[data-check = subscribe] & {
            display: block;
        }
    }

    &--delivery, &--tick {
        color: var(--mainColor);
    }

    &--tick {
        font-size: 1.25em; stroke: var(--mainColor);
    }
}