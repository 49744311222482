.discountBanner {
    margin-top: 30px;
    background-color: $secondaryColor;

    &__title {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0;
        
        small, b {
            display: block;
        }

        small {
            font-size: 0.64em;
            line-height: 1.5;
        }

        b {
            font-size: 2.45em;
        }
    }
}