.ingredients {
    .container-lg {
        position: relative;
    }

    h2 {
        font-size: 18px;
        color: #000000;

        span {
            @include media('<tablet') {
                display: block;
            }
        }
    }

    .slick-list {
        flex: 0 0 70px;

        picture,
        img {
            @include media('<tablet') {
                width: 100%;
            }
        }
    }

    .slick-dots {
        position: initial;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        @include media('<mobile') {
            width: 45px;
        }

        @include media('>=mobile') {
            width: 55px;
        }

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.5;
            width: 30px;
            height: 30px;

            & + li {
                margin-top: 4px;
            }

            &.slick-active,
            &:hover {
                opacity: 1;
                transition: opacity ease 0.2s;
            }
        }
    }

    .slider-text {
        margin-block-start: 9px;

        .sub-title {
            margin-block-end: 15px;
            font-size: 16px;
            font-weight: bold;
            color: $mainColorDark;
            text-align: center;
        }

        .content {
            line-height: 20px;

            .citation {
                font-size: 8px;
            }
        }
    }

    .slick-arrow {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 45px;
        width: 50px;
        height: 100%;
        
        @include media('<tablet') {
            padding: 8px;
        }
        
        @include media('>=tablet') {
            padding: 5px;
        }

        &::before {
            content: none;
        }

        img {
            margin-block: 10px;
        }
    }
    
    .slick-prev {
        transform: rotate(180deg); 
    }

    .slick-next {
        transform: rotate(0deg); //- override original transform 
    }

    .slider {
        &.slick-dotted.slick-slider {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px auto 10px;

            @include media('<tablet') {
                column-gap: 20px;
            }

            @include media('>=tablet') {
                column-gap: 30px;
            }
        }

        .item {
            text-align: right;

            &:nth-child(3) {
                img {
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }

    .disclaimer {
        margin-top: 1em;
    }

    &__wrap {
    
    }

    &__img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 0 auto;
        text-align: center;
        max-width: 320px;

        & > picture {
            flex-grow: 1;
        }
        
        & > picture,
        & > picture img {                
            width: 100%;
        }
    }

    &__nav {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}