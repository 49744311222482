.donationBanner {
    margin-bottom: 1.5rem;

    &__img {
        max-width: 160px;
    }

    &__title {
        margin-block: 5px;
        color: $bannerTitle-color;
    }

    &__text {
        font-size: 17px; letter-spacing: 1.2px;
        text-align: center; 
    }
}