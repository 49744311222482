.testimonialsBanner {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    background-color: var(--testimonialsBannerBackgroundColor);
    
    &__content {
        margin-bottom: 1em;
        font-size: var(--testimonialsBannerFontSize, 18px);
        color: var(--testimonialsBannerTextColor);
        letter-spacing: 0; 

        @include media('<mobile') {
            --testimonialsBannerFontSize: 16px
        }
        
        b {
            display: block;   
            font-size: var(--testimonialsBannerBoldFontSize, 32px);
        }
    }

    & + .vsl {
        position: relative;
        margin-top: 0;
        padding-top: 0;

        &::before {
            content: '';
            position: absolute;
            top: -1px;
            display: block;
            width: 100%;
            height: calc(50% + 1px);
            background-color: var(--testimonialsBannerBackgroundColor);
        }

        .vsl__title {
            display: none;
        }
    }
}