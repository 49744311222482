//- Rating
.fa-rating {
    position: relative; white-space: nowrap; vertical-align: middle; font-family: "FontAwesome";
    
    &:before {
        content: "\f005\f005\f005\f005\f005";
        font-weight: 400; vertical-align: baseline;
    }
    &:after {
        position: absolute; top: 50%; left: 0; transform: translate(0%, -50%);
    }
    
    &.s-5:before {
        content: "\f005\f005\f005\f005\f005";
    }
    &.s-4-5:before {
        content: "\f005\f005\f005\f005\f123";
    }
    &.s-4:before {
        content: "\f005\f005\f005\f005\f006";
    }
    &.s-3-5:before {
        content: "\f005\f005\f005\f123\f006";
    }
    &.s-3:before {
        content: "\f005\f005\f005\f006\f006";
    }
    &.s-2-5:before {
        content: "\f005\f005\f123\f006\f006";
    }
    &.s-2:before {
        content: "\f005\f005\f006\f006\f006";
    }
    &.s-1-5:before {
        content: "\f005\f123\f006\f006\f006";
    }
    &.s-1:before {
        content: "\f005\f006\f006\f006\f006";
    }
    &.s-0-5:before {
        content: "\f123\f006\f006\f006\f006";
    }
    &.s-0:before {
        content: "\3000\3000\3000\3000\3000";
    }
    &.blue {
        color: #2d81ea;
    }
    &.blue[data-rating] {
        cursor: pointer;
    }
}

.rating {
    display: inline-block; line-height: 1em; overflow: hidden; position: relative; top: 0; margin: 0; width: 5.5em; height: 1em;
    font-size: 27px; letter-spacing: .2em;  color: $rating-color;  text-align: left;

    span {
        overflow: hidden; width: 100%; float: left; top: 0; left: 0; position: absolute; padding-top: 1.5em;

        &:before {
            float: left;top: 0;left: 0;position: absolute;
        }
    }
}
