@import 'variables';

.header {
    padding-top: map-get($section-inner, mobile);
    padding-bottom: map-get($section-inner, mobile);
    color: $title-color;

    &--blueGradientBg {
        background: $header-bg;
        background: $header-gradient-bg;

        .icon {
            color: $white;
        }
    }

    &--whiteBg {
        background-color: $white;

        .icon {
            color: $mainColorLight;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__logo {
        display: flex;
        align-items: center;
    }

    .icon {
        height: 3rem;
        font-size: 14rem;
    }

}

.social-list {
    display: flex;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        margin-left: .3rem;
    }

    .header--blueGradientBg & {
        &__item {
            background-color: $astronaut;
            box-shadow: 1px 2px 4px rgba(mix($black, $astronaut, 50%), .5);
            border-radius: 50%;
        }
    }

    .header--whiteBg & {
        &__item {
            color: $azure;

            a {
                font-size: 24px;
            }
        }
    }    
}