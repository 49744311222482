.icon {
    overflow: hidden; display: inline-block; width: 1em; height: 1em; margin: 0;
    text-align: center; vertical-align: middle; font-size: 1em; /* default-size */
    fill: $icons-main-color; /* default color */ 
    color: $icons-main-color; /* default color when currentColor is used */
    box-sizing: content-box; pointer-events: none;

    &--collapse {
        .vertical {
            [aria-expanded=true] & {
                display: none;
            }
        }
    }
}