//- Reset Extended - On this partial I'll extend the Eric Meyer reset file by adding modern and usefull selectors

//- Override default box-sizing: content-box; so width is not affected by padding and border properties
html {
    box-sizing: border-box;
}

//- Inherit root box-sizing value to all DOM elements
*,
*::before,
*::after { box-sizing: inherit; }

//- Set body to fill viewport, optimize text-rendering and smooth scrolling
body {
    min-height: 100vh; 
    font-size: 1.4rem;
    scroll-behavior: smooth; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}

//- Reset anchor look & behaviour
a { text-decoration: none; color: inherit; cursor: pointer; }

button { 
    padding: 0; 
    background-color: transparent; color: inherit; border-width: 0; cursor:pointer; 
}

//- Aditional to reset.scss, remove margin on FF3+ 
button::-moz-focus-inner,
input::-moz-focus-inner { margin: 0; }