.slick-dots {
    li {        
        @media (min-width: 768px) {
            width: 15px;
            height: 15px;
            margin: 0 4px;
        }

        @media (max-width: 767px) {
            width: 10px;
            height: 10px;
            margin: 0 2px;
        }

        button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
                content: "";
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }
        }
    }
}
