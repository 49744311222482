.textSection {
    &__title { 
        margin-bottom: 1em;      
        del {
            display: block;
            text-decoration-color: $red;
            margin-bottom: .5em;
        }
    }

    p + p {
        margin-top: 1.5em;
    }
}