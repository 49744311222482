@import "variables";

.popscreen {
    font-family: $AssistantFont;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    padding-block-start: 0;
    padding-block-end: 1.5rem;
    text-align: center;
    background-image: linear-gradient(180deg, #45516d00 60%, #45516d 100%),
        url("https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/welcome-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .container {
        position: relative;
    }

    & > [class^="container"] {
        transform: translateY(-2vh);
    }

    &__header {
        padding: 4px 0 0;

        body.popscreenV02 & {
            padding-top: 15px;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        
        body:not(.popscreenV02) & {
            --popscreen-logoWidthMob: 88px;
            --popscreen-logoWidthTab: 88px;
            --popscreen-logoWidthDesk: 88px;
        }

        body.popscreenV02 & {
            --popscreen-logoWidthMob: 140px;
            --popscreen-logoWidthTab: 140px;
            --popscreen-logoWidthDesk: 140px;
        }
        
        .icon {
            height: map-get($popscreen, logo-height);
        }
    }

    &__close-btn {
        display: flex;
        cursor: pointer;
        font-size: 24px;

        .icon {
            color: $mainColor;
        }
    }

    &__title {
        order: 1;
        margin-block-end: 15px;
        font-size: var(--popsecreen-titleFontSize, 26px);
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1;
        color: map-get($popscreen, color, offer);
        
        body:not(.popscreenV02) & {
            @include media("<mobile") {
                --popsecreen-titleFontSize: 24px;
            }
        }

        body.popscreenV02 & {
            --popsecreen-titleFontSize: 30px;

            letter-spacing: normal;

            @include media("<mobile") {
                --popsecreen-titleFontSize: 25px;
            }
        }

        span {
            --popscreen-titleInc-fs: 1.92em;
            --popscreen-offer-color: #{map-get($popscreen, color, main)};

            display: block;
            padding-block: 5px;
            color: var(--popscreen-offer-color);
            
            body.popscreenV02 & {
                --popscreen-titleInc-fs: 2.6666em;
                --popscreen-offer-color: #{$mainColorLight};
            }
        }
    }

    &__image-wrapper {
        &--second {
            margin: 0 auto 15px;
            width: var(--popscreen-secondImgWidth, 154px);
            order: 3;
            
            body.popscreenV02 & {
                --popscreen-secondImgWidth: 340px;
            }
        }
    }

    &__image {
        @include media("<=mobile") {
            --popscreen-secondImg-w: 50vw;
        }

        body.popscreenV02 & {
            --popscreen-secondImg-w: 80vw;

            @include media('>iPhonePlus') {
                width: 100%;
            }
        }
    }

    .btn {
        padding-block: 7px;
        font-size: var(--popscreen-btnFontSize, 18px);
        line-height: 1;
        max-width: 345px;
        width: 100%;
        box-shadow: 2px 2px 3px #45516d38;
        font-weight: bold;
        border-radius: 29px;
        cursor: pointer;

        body:not(.popscreenV02) & {
            background: $white;

            &,
            &:not([href]),
            &:hover {
                color: $mainColorLight;
            }

            @include media(">tablet") {
                padding-inline: 48px;
            }
        }

        body.popscreenV02 & {
            --popscreen-btnFontSize: 24px;

            min-width: unset;
            max-width: unset;
            padding: .6666em 1em;
            font-family: inherit;
            background: $mainColorLight;

            &,
            &:not([href]),
            &:hover {
                color: $white;
            }

            @include media("<mobile") {
                --popscreen-btnFontSize: 22px;
            }
            
            @include media(">iPhonePlus") {
                width: auto;
                padding-inline: 2em;
            }
        }
    }

    &__decline {
        color: map-get($popscreen, color, main);
        text-decoration: underline;
        font-size: 16px;
        cursor: pointer;
    }

    &__footer {
        padding: 0;
        .cta-wrap {
            padding: 0;
            margin: 0;
        }
    }
}
