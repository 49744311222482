.salesBanner {
    cursor: pointer;

    @include media('>=desktop') {
        overflow: hidden;
        position: relative;
        height: 120px;

        img {
            position: absolute; left: 50%; transform: translateX(-50%);
            width: auto; min-width: 1920px;
        }
    }

    &.isSticky {
        position: sticky; top: 0; z-index: 999;
    }
}