.reviewsTicker {    
    margin-top: 15px;

    &:last-child {
        margin-bottom: 4rem;
    }

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        margin-top: 0;
        z-index: 99;
    }
    
    &__content {
        position: relative;
        padding-block: 10px;
        text-align: center;
        color: $white;
        background-color: $secondaryColor;
        z-index: 1;
    }

    .container {
        display: flex;
        justify-content: center; 
        align-items: center;
        flex-direction: column;
        row-gap: .33em;
    }

    &__body {
        display: none;
        
        &:first-child {
            display: block;
        }
    } 

    &__comment p,
    .rating {
        font-size: 16px;
    }

    &__comment p {
        line-height: 1.5;
    }

    .cart--sliderNoImages & {
        margin-bottom: 30px;
        z-index: 2;
    }
}