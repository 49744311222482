@import 'variables';
:root {
    --reviemsImageSize: 128px;
}

.reviews {    
    margin-top: 0;

    .slick-track{
        display: block; height: auto;
    }

    &__title{
        margin-bottom: 8px;
    }

    &__list { 
        .slick-list{            
            padding: 0 25px 0 18px!important ;                       
        }
    }

    &__item-wrap {
        padding: 8px 2px 0; height: auto;
        @include media('>=desktop') {
          width: 33%;
        }
    }

    &__item {
        position: relative; 
        display: flex; flex-direction: column; text-align: left; height: 100%;
        box-shadow: map-deep-get($reviewsItem, box-shadow); margin: 8px 2px 24px;
        padding:  map-deep-get($reviewsItem , padding);
        border-radius:  map-deep-get($reviewsItem , border-radius);
        background-color: map-deep-get($reviewsItem,  background-color);

        &--hasImg {
            overflow: hidden;
        }

        &--hasRoundImg {
            overflow: visible;
            margin-top: calc(var(--reviemsImageSize) / 2);
            padding-top: 0;

            .reviews__image-wrapper {
                overflow: hidden;
                width: var(--reviemsImageSize);
                height: var(--reviemsImageSize);
                margin-top: calc(var(--reviemsImageSize) / -2);
                margin-left: auto;
                margin-right: auto;
                border: 4px solid #fff;
                border-radius: 50%;
                box-shadow: map-deep-get($reviewsItem, box-shadow);
            }
        }        
    }

    &__image-wrapper {
        position: relative;
        height: 84vw;
        max-height: 429px;
        margin: -13px -14px 15px -11px;
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        min-width: 100%;
        transform: translate(-50%,-50%);
        overflow: hidden;

        img {
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            object-fit: cover;
        }
    } 

    &__top {
        display: flex; justify-content: space-between; margin-bottom: 15px; 
    }

    &__date {
        display: inline-block; font-size: 14px; color: $grey;
    }

    &__item-title {
        margin-top: 0;
        color: $mainColorLight;
        font-size: 18px;
    }

    &__comment {
        margin-top: 5px;
        color: $mainColor;

        @include media('<mobile') {
            font-size: 14px;
        }
        
        @include media('>=mobile') {
            font-size: 16px;
        }
    }

    &__info {
        color: $lightPink; align-self: flex-end;  
        position: absolute; bottom: 15px;
        font-size: 18px;
    }

    &__total {
        margin-top: 30px;
    }

    &__rating {
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-bottom: 10px;

        .fa-rating {
            font-size: 24px;
            letter-spacing: 0.2em;
            color: var(--ratingColor);
            display: flex;
        }
    }

    &__rating-text,
    &__rating-load {
        color: $mainColorLight;
        font-weight: 700;
    }

    &__rating-text {
        font-size: 40px;
        line-height: 0.8;
    }

    &__rating-load {
        align-self: center;
        font-size: 1.2em;
    }

    &__base {
        width: 100%;
        text-align: center;
        color: $mainColorLight;
        font-size: 16px;
    }

    &__verified {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        margin-top: 2px;
        font-size: 0.667em;

        .icon {
            color: inherit;

            @include media ('<mobile') {
                font-size: 1.5em;
            }

            @include media ('>=mobile') {
                font-size: 1.4em;
            }
        }
    }

    .rating {
        @include media ('<mobile') {
            font-size: 20px;
        }

        @include media ('>=mobile', '<iPhonePlus') {
            font-size: 25px;
        }
    }

    &--pages {
        letter-spacing: normal;
        
        .reviews {
            &__total {
                margin-bottom: 20px;
            }

            &__page {
                margin-bottom: 40px;
            }

            &__item {
                box-shadow: none;
                border-radius: 0;
                padding: 1.5em calc($grid-gutter-width / 2) 50px;
                margin: 1em 0 0;
            }

            &__item-title {
                font-weight: 700;
            }

            &__item-wrap {
                padding: 0;
                width: 100%;
            }

            &__top {
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: -1.5em;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: $grey;
                }
            }

            &__info {
                bottom: 5px;
                display: flex;
                align-items: center;
                gap: 10px;
                line-height: 1;
                color: $darkGrey;

                @include media ("<mobile") {
                    font-size: 16px;
                }
            }

            &__date {
                color: $darkGrey;
            }

            &__pages {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 40px;
                margin-top: 20px;
            }
        }

        .rating {
            font-size: 18px;
        }
        
        .paginationjs {
            width: 100%;
            font-size: 16px;
            font-weight: 700;
            color: $mainColorLight;
        }

        .paginationjs-nav.J-paginationjs-nav {
            display: none;
        }

        .paginationjs-pages ul {
            display: flex;
            justify-content: space-between;
            
            @include media("<tablet") {
                gap: 10px
            }

            @include media(">=tablet") {
                gap: 34px;
            }
        }

        .paginationjs-page {
            position: relative;

            &.active {
                color: #fff;

                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    width: 40px;
                    height: 40px;
                    background: #3A61A0;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    z-index: 0;
                }
            }

            a {
                position: relative;
                font-family: var(--mainFont);
            }
        }

        .paginationjs-prev, .paginationjs-next {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 400;
            color: $mainColorLight;
            z-index: 1;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
            }

            &.disabled {
                opacity: 0.2;
                pointer-events: none;
            }
        }

        .icon {
            color: inherit;
            
            &--chevron-prev {
                transform: rotate(90deg);
            }

            &--chevron-next {
                transform: rotate(-90deg);
            }
        }

        .disclaimer {
            color: $grey;
        }
    }
}
