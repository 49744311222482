//- Schema related mixins

//- 1. Linear Gradient mixin
//- Usage: $direction: vertical | horizonal | value'deg'
@mixin linear-gradient($direction, $start-color, $end-color) {
    background: $start-color;
    @if $direction == "vertical" {
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $direction == "horizontal" {
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: linear-gradient(#{$direction}, $start-color, $end-color);
    }
}