@import 'variables';

.citations {
    width: 100%; margin-top: 0; padding-top: 0; padding-bottom: 0;
    background-color: $citation-bg; border-top: 2px solid $highlight-color; 
    
    body:not(.v03):not(.v05) & {
        border-top: 4px solid darken($citation-bg, 12);
    }

    h3 {
        margin: 0;
    }
    
    &__title {
        display: flex; justify-content: center; align-items: center; padding-top: 2rem; padding-bottom: 2rem;
        font-size: 1.4rem; font-weight: $font-weight-normal; letter-spacing: 1px; color: $accordion-title;
    }

    &__answer {
        padding-bottom: 2rem;
        font-size: 1.2rem; line-height: 1.33333; letter-spacing: 1px;
    }

    a {
        text-decoration: underline;
    }

    .icon--collapse {
        margin-left: .5rem;
        font-size: 1.4rem; color: inherit;
    }
}