@import 'variables';

.countdown {
    --countdownGap: .1em;
    --countdownDigit-color: var(--timelineSensitiveBanner-color);
    
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: var(--countdownGap);
    flex: 0 0 auto;
    font-size: var(--countdownDigit-fs, 40px);
    font-weight: 700;
    text-align: center;
    line-height: 1;
    color: white;

    @include media('<mobile') {
        --countdownGap: .05em;
        --countdownDigit-fs: 34px;        
    }

    @include media('>=tablet', '<desktop') {
        --countdownDigit-fs: 44px; 
    }

    @include media('>=desktop') {
        --countdownDigit-fs: 48px; 
    }

    &__digit {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: .75em;
        height: 1.1em;
        padding: 0 .1em;
        line-height: 1.05;
        background-color: var(--countdownDigit-color);
        border-radius: 2px;
    }

    &__divider {
        margin-right: calc(var(--countdownGap) * -.5);
        margin-left: calc(var(--countdownGap) * -.5);
        color: var(--countdownDigit-color);
    }

    .rotateY {
        & > * {
            animation: rotateY ease-in-out 1s infinite;
        }
    }
}

@keyframes rotateY {
    0% {
        transform: translateY(0%);
    }
    98% {
        transform: translateY(-210%);
    }
    100% {
        transform: translateY(-200%);
    }
}

@-webkit-keyframes rotateY {
    0% {
        transform: translateY(0%);
    }
    98% {
        transform: translateY(-210%);
    }
    100% {
        transform: translateY(-200%);
    }
}

@-moz-keyframes rotateY {
    0% {
        transform: translateY(0%);
    }
    98% {
        transform: translateY(-210%);
    }
    100% {
        transform: translateY(-200%);
    }
}

