.cart--radioButtons {
    --buttonShadow: 0 2px 7px rgba(0, 0, 0, 0.28);

    letter-spacing: normal;

    [class$="__input"] {

        &::before {
        }
    }

    [class$="__label"] {
    }

    .container-sm {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .cart {
        &__title {          
            --cartGap: 1.3em;
            text-align: center;

            @include media("<tablet-mid") {
            }

            @include media(">=tablet-mid") {
                --cartTitleFontWeight: bold;
            }
        }

        &__products {

            @include media("<tablet-mid") {
            }
        }

        &__product {
        }
    }

    .cart__products,
    .subscribe-box {

        .active {
            [class$="__input"] {
                &::before {
                }
            }
        }
    }

    .subscribe-box {

        &__default {

            @include media("<tablet-mid") {
            }

            @include media(">=tablet-mid") {
            }

            @include media(">=tablet-mid", "<1075px") {
            }

            @include media(">=1075px") {
            }

            &:not(.active) {
            }
        }
    }

    .summary {
        --cartGap: 0;
        --summaryBorderWidth: 0;

        @include media("<tablet-mid") {
        }

        @include media(">=tablet-mid") {
        }

        &__item {
            &--details {
                justify-content: center;

                @include media("<tablet-mid") {
                }

                @include media(">=tablet-mid") {
                }

                @include media(">=tablet-mid", "<desktop") {
                }

                @include media(">=desktop") {
                }
            }

            &--resume {
                @include media(">=tablet-mid") {
                }
            }

            .offer,
            .retail {
                @include media("<tablet-mid") {
                }

                @include media(">=tablet-mid") {
                }
            }

            .offer {

                @include media("<tablet-mid") {
                }

                &__country {
                }
            }

            .retail {

                @include media("<tablet-mid") {
                    --retailFontSize: 14px;
                }

                @include media(">=tablet-mid") {
                }
            }

            .stock,
            .offerShipping {

                @include media("<tablet-mid") {
                }
            }

            .stock {
            }

            .offerShipping {
                --labelsFontSize: 16px;
                --labelsColor: white;
                --labelsFontWeight: normal;

                .hide-td {
                    @include media(">=tablet-mid") {
                        display: inline-block;
                    }
                }
            }

            .per-unit {

                @include media("<tablet-mid") {
                }

                &__item {

                    &--units {
                        @include media("<tablet-mid") {
                        }
                    }

                    &--value {
                        @include media("<tablet-mid") {
                        }
                    }
                }
            }
        }
    }

    .caption {
    }

    .badge {
    }

    .saving,
    .shipping {
    }

    .disclaimer-title {

        .icon {
        }
    }

    .disclaimer-box {
        --disclaimerBackgroundColor: transparent;

        @include media("<tablet-mid") {
        }

        &.open {
            display: flex;
            flex-direction: column;
            gap: 1.5em;
            margin-bottom: 0;
        }

        a {
        }

        &__header {
        }

        &__title {
            align-items: center;
            justify-content: center;
            font-size: var(--disclaimerFontSize, 16px);

            @include media("<mobile") {
                --disclaimerFontSize: 14px;
            }

            .icon {
                display: block;
            }

            &.checked {
                .icon {
                }
            }
        }

        &__close {
            display: none;
        }

        &__content {
            order: -1;
        }

        &__checked {
        }
    }

    .cta {
        border-radius: var(--cartBorderRadius);
        width: 100%;
        font-weight: bold;

        @include media("<mobile") {
            font-size: 1.75rem;
        }
    }
}

.cart--radioButtons-v01 {
    [class$="__input"] {
        &::before {
        }
    }

    [class$="__label"] {

        @include media(">=tablet-mid") {
        }

        @include media(">=tablet-mid", "<1075px") {
        }

        @include media(">=1075px") {
        }
    }

    .cart {
        &__products {

            @include media(">=tablet-mid") {
            }
        }

        &__product {

            @include media("<tablet-mid") {
            }

            @include media(">=tablet-mid", "<1075px") {
            }

            @include media(">=tablet-mid") {
            }
        }

        &__input {
            @include media("<tablet-mid") {
            }
        }
    }

    .cart__products,
    .subscribe-box {
        & > *:hover {
            @include media(">=tablet-mid") {
            }
        }

        .active {
            @include media("<tablet-mid") {
            }

            @include media(">=tablet-mid") {
            }

            [class$="__input"] {

                &::before {
                }
            }
        }
    }

    .subscribe-box {

        @include media("<tablet-mid") {
        }

        @include media(">=tablet-mid") {
        }

        &__default {

            @include media("<tablet-mid") {
            }

            @include media(">=tablet-mid") {
            }

            @include media(">=tablet-mid", "<1075px") {
            }

            @include media(">=1075px") {
            }

            &.active {
                @include media("<tablet-mid") {

                    &::before {
                    }
                }
            }

            .price {
                @include media(">=tablet-mid") {
                }

                &__country {
                }
            }
        }
    }

    .subscribe-wrap {
        &__label {

            @include media("<mobile") {
            }

            @include media(">=mobile", "<tablet-mid") {
            }

            @include media("<tablet-mid") {
            }
        }
    }

    .badge {
        @include media("<tablet-mid") {
        }

        @include media(">=tablet-mid", "<1090px") {
        }

        @include media(">=tablet-mid") {
        }
    }

    .saving,
    .shipping {
        @include media("<tablet-mid") {
            @at-root .cart--radioButtons-v01 .cart__product.active {
            }
        }
    }

    .extra {
        .saving,
        .shipping {
            @include media("<tablet-mid") {
            }

            @include media(">=tablet-mid") {
            }
        }
    }
}

.cart--radioButtons-v02 {
    --cartBorderRadius: 8px;

    [class$="__input"] {

        @include media('<mobile') {
        }

        @include media('>=mobile') {
            --radioButtonSize: 32px;
        }

        .icon {
            --productIconInputSize: 1.5em;

            @include media('<mobile') {
            }
    
            @include media('>=mobile') {
            }
        }
    }

    [class$="__label"] {
    }

    .cart {
        &__title {
            --cartTitleFontSize: 1.6em;
            --cartTitleColor: #{$mainColorLight};
            order: -1;
        }

        &__products {
            --cartBorderWidth: 0;
            order: -1;
            background-color: white;
            box-shadow: var(--buttonShadow);

            .active {
                [class$="__input"] {

                    &::before {
                        --radioButtonColor: #{$colorLight};
                    }

                    .icon {
                    }
                }
            }
        }

        &__product {
            padding-block: 2em;

            @include media('<mobile') {
            }

            @include media('>=mobile') {
            }

            &:hover {
            }

            & + .cart__product {
                --borderColorInitial: hsl(var(--textColor_h), var(--textColor_s), calc(var(--textColor_l) + 83%));
            }

            &:first-child {
            }

            &:last-child {
            }
        }

        &__label {
            grid-template-areas: "caption retail offer" "perUnit saving saving";
            grid-row-gap: 8px;

            @include media('<mobile') {
                --captionFontSize: 16px;
            }
    
            @include media('>=mobile') {
            }

            @include media('<tablet-mid') {
            }

            @include media('>=tablet-mid') {
            }

            .caption {
            }
            
            .per-unit__item, .offerShipping {
            }

            .per-unit__title {
                
            }

            .per-unit__item {
                --perUnitFontSize: 16px;
                grid-column-end: -2;
                font-weight: normal;

                @include media("<mobile") {
                    --perUnitFontSize: 14px;
                }
            }

            .retail {
                font-weight: normal;
            }
            
            .offer {
            }
            
            .saving {
            }
        }
    }

    //- Product options and Mode CTAs active state
    .cart__products,
    .subscribe-box {
        & > *:hover {
            @include media(">=tablet-mid") {
            }
        }
    }

    .subscribe-box {
        --cartGap: 1.4em;
        order: -1;

        &__default {
            --cartGap: .25em;
            --colorInitial: #{$mainColorLight};
            --cartBorderWidth: none;
            border-radius: var(--cartBorderRadius);
            box-shadow: 3px 3px 3px hsla(var(--textColor), 0.16);

            &.active {
                --backgroundColorActive: #{$mainColorLight};
                color: white;
            }
        }
    }

    .subscribe-wrap {
        &__label {
            height: fit-content;
            line-height: 1.3;

            @include media("<tablet") {
                --captionFontSize: 16px;
            }

            @include media('<mobile') {
                --captionFontSize: 14px;
            }
    
            @include media('>=mobile') {
            }
        }
    }

    .badge {
        --badgeHeight: 24px;
        padding-inline:  (calc(var(--badgeHeight) / 1.5));

        &:after {
        }

        @include media('<mobile') {
        }

        @include media('>=mobile') {
        }
    }

    [data-units="3"] .badge {
        --badgeColor: white;
    }

    [data-units="6"] .badge {
        --badgeColor: white;
        --badgeBackground: #{$colorLight};
    }

    .per-unit__item, .offerShipping {
    }

    .per-unit__item {
        &--units {
            @include media("<tablet-mid") {
            }
        }

        &--value {
        }
    }

    .retail {
        --retailColor: hsl(var(--textColor_h), var(--textColor_s), calc(var(--textColor_l) + 44%));    
        
        @include media("<tablet-mid") {
        }
        
        @include media(">=tablet-mid") {
        }
    }
        
    .saving {
        margin-right: 0.25em;

        &::before {
            --savingBackgroundColor: hsl(calc(var(--secondaryColor_h) - 82), calc(var(--secondaryColor_s) - 19%), calc(var(--secondaryColor_l) + 23%));
            border-radius: 4px;
        }

        @include media('>=tablet-mid') {
        }
    }
}

.cart {
    .guaranteeBanner {

        &__title {
        }

        &__list {

            @include media("<mobile") {
            }

            @include media(">=mobile", "<tablet-mid") {
            }

            @include media("<1075px") {
            }

            @include media(">=1075px") {
            }
        }

        &__item {

            @include media("<mobile") {
            }
        }

        .disclaimer {
        }
    }

    .benefits-list {
        order: -2;
        margin-bottom: 0;

        @include media("<tablet") {
            column-gap: 0.5em;
        }

        &__item {
            font-weight: bold;

            @include media("<tablet") {
                --disclaimerBenefitsListFontSize: 13px;
            }

            @include media("<mobile") {
                --disclaimerBenefitsListFontSize: 11px;

            }
        }

        .icon {
            --disclaimerBenefitsListIconcolor: var(--mainColor);
        }
    }

    .hide-td {
        @include media(">=tablet-mid") {

        }
    }

    .hide-mt {
        @include media("<tablet-mid") {

        }
    }
}
