//- Base typography styles
html { 
    font: normal 62.5% / 1.4 $mainFont; //- 62.5% makes 'em' and 'rem' units to be computed to 10px so 1rem will be 10px and 1.4rem will be 14px
}

body {
    line-height: 1.1; letter-spacing: .12rem;
    //- Note text-rendering should be chosen depending on used font-family and performance goals
    text-rendering: optimizeSpeed; //- https://developer.mozilla.org/en-US/docs/Web/CSS/text-rendering
    overflow-wrap: break-word; color: $text-color;
}

h1, h2, h3 { text-align: center; }

h1 {
    font-size: 2.8rem; font-weight: $font-weight-normal; line-height: 1.17857; color: $title-color;
}

h2 {
    font-size: 2.3rem; font-weight: $font-weight-bold; line-height: 1.2; color: $title-color;

    @include media ('<mobile') {
        font-size: 2.1rem;
    }
}

h3 {
    font-size: 1.8rem; font-weight: $font-weight-bold; line-height: 1.2; color: $text-color; margin-bottom: .6em;
}

h5 {
    font-size: 1.5rem; font-weight: $font-weight-bold; line-height: 1.2; color: $text-color;
}

h6 {
    font-size: 1.4rem; font-weight: $font-weight-normal; line-height: 1.4;
}

p, ul, a {
    line-height: 1.45;
}

b {
    font-weight: $font-weight-bold;
}
sup {
    vertical-align: super;
    font-size: smaller;
}

.disclaimer {
    font-size: 12px;
    letter-spacing: 0;
}