//- Cart variables setup
$product-cta: (
    block-padding: 1rem,
    initial-bg: $white,
    initial-border: $black,
    active-color: $white,
    active-bg: $secondaryColor,
    active-border: $secondaryColor,
    border: 1px solid,
    radius: .7rem
);

$retail-color: $red;

$current-price-color: $malibu;

$unit-price-color: $tree-poppy;

$shipping-color: $secondaryColor;

$agreement-color: $secondaryColor;

$checkbox: (
    size: 2rem,
    border: 3px solid,
    border-color: $secondaryColor,
    tick-color: $tree-poppy,
    shadow: #{0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19)},
    radius: .2rem
);

$mode-cta: (
    initial-color: $white,
    initial-bg: #848fae,
    active-color: $black,
    active-bg: $white,
    letter-spacing: normal,
    line-height: 1.25,
    radius: .4rem
);

$badge: (
    color: $white,
    seller-bg: $azure,
    value-bg: $boston-blue,
    fold-bg: $cello,
    green-bg: $badgeGreen,
    grey-bg: $badgeGrey
);

:root {
    --header_bg_d: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/header_bg.jpg');
    --header_bg_m: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/header_bg_m.jpg');

    --header_left_bg_d: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_left_d.png');
    --header_right_bg_d: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_right_d.png');
    --header_left_bg_m: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_left_m.png');
    --header_right_bg_m: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_right_m.png');
}