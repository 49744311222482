.video {
    position: relative; overflow: hidden;
    text-align: center; 

    &__placeholder {
        width: 100%; opacity: 0;
    }

    .video-js {
        &.video-js--default {
            position: absolute; top: 0; left: 0; overflow: hidden; width: 100%;
            height: 100%;

            .vjs-big-play-button {
                position: relative;
            }
        }

        &.video-js--bc {
            .vjs-dock-text,
            .vjs-dock-shelf {
                display: none;
            }
    
            .vjs-current-time, .vjs-duration, .vjs-custom-control-spacer {
                display: flex;
            }

            .vjs-progress-control {
                position: absolute;
                top: -1.5em;
                width: 100%;
                height: 1.5em;
            }

            .vjs-progress-holder {
                margin: 0;
            }
        }

        .vjs-big-play-button {
            width: 100%;
            font-size: 18em; background-color: transparent; border: 0 none; 

            &::before {
                content: '';
                position: absolute; top: 0; left: 0;
                width: 100%; height: 100%;
                background: $black;
                background: linear-gradient(0deg, rgba($black,0) 0%, rgba($black,1) 100%);
            }
        }
    }

    .vjs-poster {
        background-size: cover;
    }
}
