//- Badges Banner component
@import 'variables';

.badges-banner {
    &__img {
        width: map-get($badges-banner, width); margin-bottom: 7px;
    }

    &__text {
        line-height: 1.2;
    }

    &__row {
        display: flex;
        text-align: center;
        font-weight: bold;
        letter-spacing: normal;
    }

    &__item {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}