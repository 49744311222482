@use 'sass:math';
@import 'variables';

.benefits {
    position: relative;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    letter-spacing: normal;

    &:last-child {
        margin-bottom: 3rem;
    }

    &__carousel {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        // margin-bottom: calc(map-deep-get($benefitsCarousel, slick, arrows) * 1.5);

        @include media('>=tablet') {
            --benefitsCarouselArrows: 40px;
        }
    }

    &__image {
        .benefits--carousel & {
            @include media('<tablet') {
                flex: 0 0 40%;
                width: 40%;
            }
            
            @include media('>=tablet') {
                flex: 0 0 50%;
                width: 50%;
            }
        }
    }

    &__list {
        .benefits--dots & {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        .benefits--carousel & {
            @include media('<tablet') {
                flex: 0 0 60%;
                width: 60%;
            }
            
            @include media('>=tablet') {
                flex: 0 0 50%;
                width: 50%;
            }
        }
    }

    &__item {
        @include media("<mobile") {
            font-size: 12px;
        }

        @include media(">=mobile") {
            font-size: 14px;
        }

        .benefits--dots & {
            position: absolute;
            padding-bottom: 15px;

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                border-radius: 50%;
                width: 12px;
                height: 12px;
            }
            
            &::before {
                background-color: $mainColorDark;
                transform: translateX(-50%);
            }
            
            &::after {
                border: 1px solid $mainColorDark;
                transform: translateX(-50%);
                transform-origin: center;
            }

            &:first-child {
                @include media("<mobile") {
                    top: -9%;
                    right: 4%;
                }

                @include media(">=mobile", "<iPhonePlus") {
                    top: -8%;
                    right: 4%;
                }

                @include media(">=iPhonePlus", "<tablet") {
                    top: -5%;
                    right: 4%;
                }

                @include media(">=tablet") {
                    top: -1%;
                    right: 3%;
                }

                @include media('landscape') {
                    top: -1%;
                    right: 3%;
                }
            }

            &:nth-child(2) {
                @include media("<mobile") {
                    top: 25%;
                    left: 4%;
                }

                @include media(">=mobile", "<iPhonePlus") {
                    top: 26%;
                    left: 4%;
                }

                @include media(">=iPhonePlus", "<tablet") {
                    top: 28%;
                    left: 5%;
                }

                @include media(">=tablet") {
                    top: 32%;
                    left: 5%;
                }

                @include media('landscape') {
                    top: 32%;
                    left: 5%;
                }
            }

            &:nth-child(3) {
                bottom: 37%;

                @include media("<mobile") {
                    right: 6%;
                }

                @include media(">=mobile", "<iPhonePlus") {
                    right: 6%;
                }

                @include media(">=iPhonePlus", "<tablet") {
                    right: 7%;
                }

                @include media(">=tablet") {
                    right: 8%;
                }

                @include media('landscape') {
                    right: 8%;
                }
            }

            &:last-child {
                bottom: 8%;

                @include media("<mobile") {
                    left: 2%;
                }

                @include media(">=mobile", "<iPhonePlus") {
                    left: 2%;
                }

                @include media(">=iPhonePlus", "<tablet") {
                    left: 4%;
                }

                @include media(">=tablet") {
                    left: 5%;
                }

                @include media('landscape') {
                    left: 5%;
                }
            }
        
            p {
                line-height: 1.2;
            }
        }

        .benefits--carousel & {
            display: flex;
            flex-direction: column;
            gap: .25em;
            margin: .75em;
            padding: .75em;
            background-color: $white;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 15px;
            transition: all 500ms ease-in-out !important;
            
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 0; 
                height: 0; 
                border-color: transparent $white transparent transparent;
                border-style: solid; 

                @include media('<tablet') {
                    left: -7px; 
                    border-width: 14px 7px 14px 0;  
                }

                @include media('>=tablet') {
                    left: -12px; 
                    border-width: 20px 12px 20px 0;
                }
            }

            &:not(.slick-active) {
                transform: scale(.75);
                transform-origin: center;
            }
        }

        &--anim {
            &::after {
                animation: translateXAndScale 1.5s infinite ease-in-out;
            }
        }
    }

    &__item-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: .5em;

        img {
            width: 30px;
            margin: 0;
        }

        h5,
        p {
            @include media('>=tablet') {
                font-size: 16px;
            }
        }
    }

    &__carouselControl {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex; 
        justify-content: center; 
        align-items: center;
        bottom: calc(-1.5 * map-deep-get($benefitsCarousel, slick, arrows));
        height: map-deep-get($benefitsCarousel, slick, arrows);
    }

    &__arrow {
        width: 1em;
        height: 1em;
        font-size: map-deep-get($benefitsCarousel, slick, arrows);
        
        &::before {
            display: block;
            font-family: "FontAwesome";
            font-weight: $font-weight-bold;
            font-size: .85em;
            color: $mainColorDark;
        }

        &--prev {
            &::before {
                content: '#{$fa-var-angle-left}';
            }
        }

        &--next {
            &::before {
                content: '#{$fa-var-angle-right}';
            }
        }
    }

    &__dots {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1em;
        font-size: map-deep-get($benefitsCarousel, slick, arrows);

        ul {
            position: static;
            display: flex; 
            justify-content: center; 
            align-items: center;
            height: 1em;
        }

        li {
            @include media('<tablet') {
                width: 12px; height: 12px; margin: 0 4px;
            }
    
            @include media('>=tablet') {
                width: 14px; height: 14px; margin: 0 6px;                   
            }
    
            &.slick-active {
                button {
                    &::before {
                        opacity: 1;
                    }
                }
            }
    
            button {        
                &::before {
                    background-color: $mainColorDark;
                }
            }
        }
    }

    &--carousel {
        margin-bottom: calc(map-deep-get($benefitsCarousel, slick, arrows) * 2);

        .slick-dotted.slick-slider {
            margin: 0;
        }

        .slick-track {
            display: flex;
            align-items: center;
        }
    }

    &__benefitsModalToggle {
        position: absolute;
        left: 50%;
        bottom: -40px;
        padding-block: 6px 15px;
        cursor: pointer;
        color: var(--mainColor);
        z-index: 2;
        transform: translateX(-50%);

        span {
            position: relative;
            width: 1.35em;
            height: 1.35em;
            margin-right: 0.3em;
            display: inline-block;
            font-weight: 700;

            &::before {
                content: "";
                position: absolute;
                top: -0.06em;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                border: 1px solid var(--mainColor);
                border-radius: 50%;
            }
        }
    }

    &--benefitsModal {
        margin-bottom: 50px;
    }

    .disclaimer {
        margin-top: 1em;
    }
}

.modal {
    &--learnMore {
        .modal-dialog {
            justify-content: center;
        }

        .modal-content {
            max-width: 375px;
            border: 0 none;
            border-radius: 20px 20px 20px 20px;
        }

        .modal-header {
            position: relative;
            justify-content: center;
            padding-inline: math.div($grid-gutter-width, 2);
            border-bottom: 0 none;
            border-top-right-radius: 18px;
            border-top-left-radius: 18px;
            background: $mainColorLight;
        }

        .modal-title {
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            color: $white;
            text-align: center;
            padding: 0 2em;
        }

        .close {
            opacity: 1;
            position: absolute;
            right: 0;
            width: 2.6em;
            height: 95%;
            margin-inline: 0;
            padding-block: 0;
            font-weight: 100;
            color: $white;
            font-size: 24px;

            .icon {
                color: $white;
            }
        }

        .modal-body {
            padding: 24px;
        }

        .modal-footer {
            padding: 0 #{math.div($grid-gutter-width, 2)} #{math.div($grid-gutter-width, 2)};
            border-top: 0 none;
        }
    }

    .info-list {
        font-weight: normal;
        line-height: 1.4;

        @include media('<mobile') {
            font-size: 16px;
        }
        
        @include media('>=mobile') {
            font-size: 18px;
        }

        &__item {
            position: relative;
            padding-left: 4em;
            font-size: 16px;
            
            .icon {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 3em;
                color: $mainColorLight;
            }

            & + .info-list__item {
                margin-top: 1em;
            }
        }

        &__itemTitle {
            font-weight: 700;
            color: var(--mainColor);
        }
    }
}
