//- Buttons partial
[role='button'] {
    @media (hover: hover) and (pointer: fine) {
        cursor: pointer;
    }
}

.btn { 
    display: flex; justify-content: center; align-items: center; width: 100%; margin: 0 auto;

    &.disabled, [disabled] {
        pointer-events: none;
    }
}
