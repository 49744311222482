@import "default";

//- Item bullets animation
@keyframes bulletsAnimation{
    0%   { transform: #{map-deep-get($timeline, animation, itemAnimationStart)}; }
    70%  { transform: #{map-deep-get($timeline, animation, itemAnimationMotion)}; }
    100% { transform: #{map-deep-get($timeline, animation, itemAnimationEnd)}; }
}
@-webkit-keyframes bulletsAnimation{
    0%   { transform: #{map-deep-get($timeline, animation, itemAnimationStart)}; }
    70%  { transform: #{map-deep-get($timeline, animation, itemAnimationMotion)}; }
    100% { transform: #{map-deep-get($timeline, animation, itemAnimationEnd)}; }
}
@-moz-keyframes bulletsAnimation{
    0%   { transform: #{map-deep-get($timeline, animation, itemAnimationStart)}; }
    70%  { transform: #{map-deep-get($timeline, animation, itemAnimationMotion)}; }
    100% { transform: #{map-deep-get($timeline, animation, itemAnimationEnd)}; }
}

.timeline {
    --timelineCarouselItemGap: 8vw;

    @include media('>=tablet') {
        --timelineCarouselItemGap: 20px;
    }

    &__title {

    }

    &__image {
        margin-bottom: 1em;
    }

    &__list {
        margin-bottom: 2em;
        
        &--column {
            margin-left: .75em;
            padding-left: 1.25em;
            border-left: map-deep-get($timeline, scheme, timelineListBorder);
        }

        &--carousel {

        }
    }

    &__item {
        --timelineImageSize: 162px;

        position: relative;

        & + #{&} {
            .timeline__list--column & {
                margin-top: 1em;
            }
        }

        &::before {
            .timeline__list--column & {
                content: '';
                position: absolute;
                left: -2em;
                display: inline-block;
                width: 1.4286em;
                height: 1.4286em;
                background-color: white;
                border: map-deep-get($timeline, scheme, timelineBulletBorder);
                border-radius: 50%;
                transform: scale(0);
            }
        }

        .timeline__list--carousel & {
            padding: 0 var(--timelineCarouselItemGap);
            text-align: center;
        }

        &--hasImg {
            &::before {
                .timeline__list--column & {
                    top: calc(var(--timelineImageSize) / 2);
                    transform: translateY(-50%) scale(0);
                }
            }
        }
        
        &--animBullet {
            &::before {
                .timeline__list--column & {
                    animation: bulletsAnimation 500ms ease-in-out 250ms forwards;
                }
            }
        }
    }

    &__itemImgWrap {
        position: relative;
        font-size: 22px;
    }

    &__itemImg {
        max-width: var(--timelineImageSize);
        max-height: var(--timelineImageSize);
        margin-bottom: .5em;
        border: 1px solid map-deep-get($timeline, scheme, timelineItemTitleColor);
        border-radius: 50%;

        .timeline__list--column & {
            margin-left: 0;
        }
    }

    &__item-title {
        font-weight: map-deep-get($timeline, scheme, timelineItemTitleFontWeight);
        color: map-deep-get($timeline, scheme, timelineItemTitleColor);
    }

    &__item-content {

    }

    &--hasBg {
        padding: 3rem 0;
        background-color: var(--timelineSectionBackground);
        
        & + * {
            margin-top: 0;
        }
    }

    .icon {
        &--doubeArrow {
            position: absolute;
            top: 50%;
            left: calc(100% + var(--timelineCarouselItemGap));
            color: $mainColor;
            transform: translate(-50%, -50%);
        }
    }

    //- Slick
    .slick-list {
        width: calc(100% + #{$grid-gutter-width});
        margin-left: calc(-#{$grid-gutter-width} / 2);
        cursor: pointer;
    }

    .slick-dotted {
        --timelineSlickMarginBottom: 60px;

        &.slick-slider {
            margin-bottom: var(--timelineSlickMarginBottom);
        }
    }

    .slick-dots {
        bottom: calc((var(--timelineSlickMarginBottom) / -2));

        li {
            margin: 0 4px;

            button {
                &:before {
                    opacity: 1;
                    background-color: white;
                }
            }

            &.slick-active {
                button {
                    &:before {
                        background-color: map-deep-get($timeline, slick, timelineSlickDotsColor);
                    }
                }
            }
        }
    }
}