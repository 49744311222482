@use "sass:math";

.timeSensitiveBanner {
    --timelineSensitiveBanner-color: var(--pageBgColor, var(--textColor));

    @include linear-gradient("0", #ffab26 0%, #fd9329 100%);
    position: relative;
    overflow: hidden;
    display: none;
    flex-direction: column;
    gap: var(--timeSensitiveBanner-gap, .5em);
    padding: math.div($grid-gutter-width, 2);
    font-family: $mainFont;
    line-height: 1.1;
    letter-spacing: 0;
    color: $white;
    z-index: 100;

    body.show-timeSensitiveBanner & {
        display: flex;
    }

    @include media("hover", "fine") {
        cursor: pointer;
    }

    &__textWrapper {
        font-weight: 600;
        text-align: center;
        line-height: var(--timelineSensitiveBanner-lh, 1.2);
    }

    &__firstLine {
        font-size: var(--timeSensitiveBannerFirst-fs, 16px);

        @include media('<mobile') {
            --timeSensitiveBannerFirst-fs: 14px;
        }

        @include media(">=tablet", "<desktop") {
            --timeSensitiveBannerFirst-fs: 18px;
        }

        @include media(">=desktop") {
            --timeSensitiveBannerFirst-fs: 18px;
        }
    }

    &__secondLine {
        font-size: var(--timeSensitiveBannerSecond-fs, 26px);

        @include media("<mobile") {
            --timeSensitiveBannerSecond-fs: 20px;
        }

        @include media(">=tablet") {
            --timeSensitiveBannerSecond-fs: 34px;
        }

        @include media(">=desktop") {
            --timeSensitiveBannerSecond-fs: 40px;
        }
    }

    &__thirdLine {
        font-size: var(--timeSensitiveBannerThird-fs, 12px);
        color: var(--timelineSensitiveBanner-color);

        @include media("<mobile") {
            --timeSensitiveBannerThird-fs: 10px;
        }

        @include media(">=tablet", "<desktop") {
            --timeSensitiveBannerThird-fs: 14px;
        }

        @include media(">=desktop") {
            --timeSensitiveBannerThird-fs: 16px;
        }
    }

    sup {
        vertical-align: text-top;
    }

    .icon {
        position: absolute;
        display: flex;
        bottom: math.div($grid-gutter-width, 2);
        height: .5925em;
        font-size: var(--timeSensitiveBannerIcon-fs, 5em);
        color: var(--timelineSensitiveBanner-color);
        box-sizing: border-box;

        @include media("<mobile") {
            --timeSensitiveBannerIcon-fs: 4.25em;

            left: math.div($grid-gutter-width, 2);
        }

        @include media('>=mobile') {
            right: 50%;
            transform: translateX(-150%);
        }

        @include media(">=tablet", "<desktop") {
            --timeSensitiveBannerIcon-fs: 5.5em;
        }

        @include media(">=desktop") {
            --timeSensitiveBannerIcon-fs: 7em;
        }
    }

    .disclaimer {
        position: absolute;
        bottom: math.div($grid-gutter-width, 2);
        color: var(--timelineSensitiveBanner-color);
        font-size: var(--timelineSensitiveBannerDisclaimer-fs, 10px);
        font-weight: 700;

        @include media("<mobile") {
            --timelineSensitiveBannerDisclaimer-fs: 8px;

            right: math.div($grid-gutter-width, 2);
        }

        @include media('>=mobile') {
            left: 50%;
            transform: translateX(95%);
        }

        @include media('>=tablet', '<desktop') {
            --timelineSensitiveBannerDisclaimer-fs: 12px;
        }
    
        @include media('>=desktop') {
            --timelineSensitiveBannerDisclaimer-fs: 14px;
        }
    }

    &.timeSensitiveBanner--compact {
        position: sticky;
        transition: 0.4s;
        flex-direction: row;
        align-items: center;
        animation: slideFromTop 0.5s ease-in-out forwards;
        
        @include media('<mobile-mid') {
            justify-content: space-around;
        }

        @include media('<tablet') {
            padding: math.div($grid-gutter-width, 3);
        }
        
        @include media('>=mobile-mid') {
            justify-content: center;
        }

        @include media('>=tablet') {
            --timeSensitiveBanner-gap: 1.5em;
        }

        .icon {
            position: relative;
            right: auto;
            bottom: auto;
            transform: none;
            order: 1;

            @include media("<tablet") {
                display: none;
            }

            @include media('>=tablet') {
                --timeSensitiveBannerIcon-fs: 3.5em;
            }
        }

        .timeSensitiveBanner {
            &__textWrapper {
                --timelineSensitiveBanner-lh: 1.15;

                order: 2;
            }

            &__firstLine {
                @include media('<mobile') {
                    --timeSensitiveBannerFirst-fs: 10px;
                }

                @include media('>=mobile', '<tablet') {
                    --timeSensitiveBannerFirst-fs: 12px;
                }

                @include media('>=tablet', '<desktop') {
                    --timeSensitiveBannerFirst-fs: 16px;
                }
            }

            &__secondLine {
                @include media('<mobile') {
                    --timeSensitiveBannerSecond-fs: 14px;
                }

                @include media('>=mobile', '<tablet') {
                    --timeSensitiveBannerSecond-fs: 16px;
                }

                @include media('>=tablet', '<desktop') {
                    --timeSensitiveBannerSecond-fs: 18px;
                }

                @include media('>=desktop') {
                    --timeSensitiveBannerSecond-fs: 20px;
                }
            }

            &__thirdLine {
                @include media("<mobile") {
                    --timeSensitiveBannerThird-fs: 8px;
                }
        
                @include media(">=mobile", "<tablet") {
                    --timeSensitiveBannerThird-fs: 10px;
                }
        
                @include media(">=tablet", "<desktop") {
                    --timeSensitiveBannerThird-fs: 12px;
                }
        
                @include media(">=desktop") {
                    --timeSensitiveBannerThird-fs: 14px;
                }
            }
        }

        .countdown {
            order: 3;
        }

        .disclaimer {
            display: none;
        }
    }
}
