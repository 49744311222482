.topSection {
    position: relative;
    padding-top: 2.5rem;
    background-color: transparent;
    background-repeat: no-repeat;

    @include media("<tablet") {
        background: {
            image: var(--header_left_bg_m), var(--header_right_bg_m);
            size: 45%, 35%;
            position: -31vw -8vh, 79vw 28vh;
        }
    }

    @include media(">=tablet") {
        background-image: var(--header_left_bg_d), var(--header_right_bg_d);
    }

    @include media(">=tablet", "<desktop") {
        @include media("portrait") {
            background: {
                size: auto 75%;
                position: -10vw 3vh, 82vw 3vh;
            }
        }

        @include media("landscape") {
            background: {
                size: auto 65%;
                position: -8vw 8vh, 88vw 12vh;
            }
        }
    }

    @include media(">=desktop") {
        background: {
            size: auto 75%;
            position: -5vw 80px, 91vw 100px;
        }
    }

    & > * {
        position: relative;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &::before {
        height: 100%;
        background: {
            repeat: no-repeat;
            position: center top;
            size: 100% auto;
            attachment: fixed;
        }
        z-index: -1;

        @include media("<tablet") {
            background-image: var(--header_bg_m);
        }

        @include media(">=tablet") {
            background-image: var(--header_bg_d);
        }
    }

    &::after {
        height: 80%;
        background-image: linear-gradient(to bottom, rgba(69, 81, 109, 0), rgba(69, 81, 109, 0.6));
        z-index: -1;
    }
    
    &__mainTitle {
        font-size: var(--cartMainTitle-fs, 20px);
        letter-spacing: normal;
        margin-bottom: 1.25em;

        &--v01 {
            font-weight: $font-weight-bold;
            color: $mainColorDark;
        }

        &--v02 {
            --cartMainTitle-fs: 26px;
            font-weight: normal;
            color: #45516d;
            padding-left: 5px;
            padding-right: 5px;

            span {
                font-size: 0.923em;
            }

            @include media("<mobile") {
                --cartMainTitle-fs: 22px;
            }

            @include media("<tablet") {
                span {
                    letter-spacing: -0.04em;
                }
            }
        }

        &--v03,
        &--DynamicHL {
            font-weight: $font-weight-bold;
            color: $mainColorDark;
            margin-bottom: 1.7em;
        }

        &--v03 {
            max-width: 90%;
            
            @include media("<mobile") {
                --cartMainTitle-fs: 19px;
            }

            @include media(">=mobile", "<tablet") {
                --cartMainTitle-fs: 22px;
            }

            @include media('>=tablet') {
                --cartMainTitle-fs: 28px;
            }
        }

        &--DynamicHL {
            display: flex;
            flex-direction: column;

            @include media("<mobile") {
                --cartMainTitle-fs: 20px;
            }

            @include media(">=mobile") {
                --cartMainTitle-fs: 24px;
            }

            span {
                display: block;
            }

            #swapText {
                margin-bottom: .25em;
                font-size: 1.33em;
                color: var(--CTA);
            }

            .fadeIn {
                animation-name: appear;
                animation-duration: 0.5s;
            }
    
            .fadeOut {
                animation-name: disappear;
                animation-duration: 0.5s;
            }
        }

        &--DynamicHLReverse {
            flex-direction: column-reverse;

            #swapText {
                margin-top: .25em;
                margin-bottom: 0;
            }
        }

        &--withBackground {
            max-width: unset;
            padding: 0.75em;
            margin-top: -2.5rem;
            margin-bottom: 2em;
            font-size: 24px;
            background: $mainColorLight;
        }
    }

    .benefits__item {
        p {
            text-align: center;
        }
    }

    &__icon {
        width: 100%;
        height: 4.5rem;
        margin-block: var(--iconMarginTop) 2rem;
        font-size: 4rem;
        line-height: 1;
        text-align: center;

        .icon {
            color: $mainColorDark;
            stroke: none;
            animation: bounce-with-distortion 2s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
        }
    }
}