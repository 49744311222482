@import 'variables';

.cart-banner {
    margin-top: 0;
    text-align: center; 
    background: $banner-bg;
    background: $banner-gradient;
    
    body.v03 & {
        border-top: 4px solid darken($light-gray, 12);
    }

    &__title {
        margin-bottom: 1.4rem;
        font-size: 2rem; font-weight: normal;
    }
}

.v02{
    // @import 'v02_variables';

    // .cart-banner {
    //     margin-top: 0;
    //     text-align: center; background: map-get($cart-banner, bg-color); box-shadow: map-get($cart-banner, shadow) map-get($shadow, color);

    //     &__title {
    //         margin-bottom: 1.4rem;
    //         font-size: map-get($cart-banner, font-size);
    //     }
    // }
}