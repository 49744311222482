@import "default";

.cart--radioButtons {
    //- generic scoped classes
    [class$="__input"] {
        position: relative;
        flex: 0 0 auto;
        width: map-deep-get($cartRadioButtons, scheme, buttons, radioButtonSize);
        height: map-deep-get($cartRadioButtons, scheme, buttons, radioButtonSize);
        border: map-deep-get($cartRadioButtons, scheme, buttons, radioButtonBorderWidth)
            map-deep-get($cartRadioButtons, scheme, buttons, radioButtonBorderStyle)
            map-deep-get($cartRadioButtons, scheme, buttons, radioButtonColor);
        border-radius: 50%;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            background-color: map-deep-get($cartRadioButtons, scheme, buttons, radioButtonColor);
            border-radius: 50%;
            transition: all 250ms ease-in-out;
        }
    }

    [class$="__label"] {
        height: 100%;
        font-size: map-deep-get($cartRadioButtons, scheme, buttons, captionFontSize);
        font-weight: map-deep-get($cartRadioButtons, scheme, buttons, captionFontWeight);
    }

    .cart {
        &__title {
            margin-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);
            font-size: map-deep-get($cartRadioButtons, scheme, title, fontSize);
            font-weight: map-deep-get($cartRadioButtons, scheme, title, fontWeight);
            color: map-deep-get($cartRadioButtons, scheme, title, color);

            @include media("<tablet-mid") {
                --cartTitleFontSize: 1.25em;
                --cartGap: 0.5em;
            }

            @include media(">=tablet-mid") {
                --cartTitleColor: #{$textColorLight};
                --cartTitleFontWeight: normal;
            }
        }

        &__products {
            display: flex;
            justify-content: center;
            gap: map-deep-get($cartRadioButtons, structure, generic, gap);
            width: 100%;

            @include media("<tablet-mid") {
                flex-direction: column;
                margin-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);
            }
        }

        &__product {
            line-height: 1.3;
            color: map-deep-get($cartRadioButtons, scheme, buttons, colorInitial);
        }
    }

    .cart__products,
    .subscribe-box {
        .active {
            [class$="__input"] {
                &::before {
                    --radioButtonColor: var(--mainColor);
                }
            }
        }
    }

    .subscribe-box {
        display: flex;
        gap: map-deep-get($cartRadioButtons, structure, generic, gap);
        width: 100%;

        &__default {
            display: flex;
            align-items: center;
            padding: map-deep-get($cartRadioButtons, structure, generic, gap);
            color: map-deep-get($cartRadioButtons, scheme, buttons, colorInitial);

            @include media("<tablet-mid") {
                flex: 1 1 100%;
                gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);
                width: 100%;
                padding-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);
                padding-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);
            }

            @include media(">=tablet-mid") {
                gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 1.5);
                text-transform: uppercase;
                border: map-deep-get($cartRadioButtons, scheme, generic, borderWidth)
                    map-deep-get($cartRadioButtons, scheme, generic, borderStyle)
                    map-deep-get($cartRadioButtons, scheme, buttons, borderColorInitial);
            }

            @include media(">=tablet-mid", "<1075px") {
                padding-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2.4);
                padding-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2.4);
            }

            @include media(">=1075px") {
                padding-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 1.5);
                padding-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 1.5);
            }

            &:not(.active) {
                cursor: pointer;
            }
        }
    }

    .summary {
        margin-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);

        @include media("<tablet-mid") {
            --cartGap: 0.25em;
        }

        @include media(">=tablet-mid") {
            padding-bottom: calc(#{map-deep-get($cartRadioButtons, structure, generic, gap)} / 2);
            border-bottom: var(--summaryBorderWidth, 1px) var(--cartBorderStyle, solid)
                map-deep-get($cartRadioButtons, scheme, generic, hrBorderColor);
        }

        &__item {
            &--details {
                @include media("<tablet-mid") {
                    display: grid;
                    grid-template-columns: auto;
                    grid-template-areas: "price retail" "label label";
                    column-gap: calc(var(--grid-gutter-width) / 4);
                }

                @include media(">=tablet-mid") {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: map-deep-get($cartRadioButtons, structure, generic, gap);
                }

                @include media(">=tablet-mid", "<desktop") {
                    --cartGap: 0.5em;
                }

                @include media(">=desktop") {
                    --cartGap: 1.4em;
                }
            }

            &--resume {
                @include media(">=tablet-mid") {
                    margin-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 2);
                }
            }

            .offer,
            .retail {
                @include media("<tablet-mid") {
                }

                @include media(">=tablet-mid") {
                }
            }

            .offer {
                font-size: map-deep-get($cartRadioButtons, scheme, summary, prices, fontSize);
                font-weight: map-deep-get($cartRadioButtons, scheme, summary, prices, fontWeight);
                color: map-deep-get($cartRadioButtons, scheme, summary, prices, color);

                @include media("<tablet-mid") {
                    grid-area: price;
                    justify-self: flex-end;
                }

                &__country {
                    font-size: 0.75em;
                }
            }

            .retail {
                font-size: map-deep-get($cartRadioButtons, scheme, summary, retail, fontSize);
                font-weight: map-deep-get($cartRadioButtons, scheme, summary, retail, fontWeight);
                color: map-deep-get($cartRadioButtons, scheme, summary, retail, color);

                @include media("<tablet-mid") {
                    --retailFontSize: 1.375em;
                    --retailColor: var(--textColor);

                    grid-area: retail;
                    justify-self: flex-start;
                    align-self: center;
                }

                @include media(">=tablet-mid") {
                    text-decoration: map-deep-get($cartRadioButtons, scheme, summary, retail, textDecoration);
                }
            }

            .stock,
            .offerShipping {
                font-size: map-deep-get($cartRadioButtons, scheme, summary, labels, fontSize);
                font-weight: map-deep-get($cartRadioButtons, scheme, summary, labels, fontWeight);
                color: map-deep-get($cartRadioButtons, scheme, summary, labels, color);

                @include media("<tablet-mid") {
                    --labelsFontSize: 1em;
                    --labelsFontWeight: bold;

                    grid-area: label;
                }
            }

            .stock {
                white-space: nowrap;
            }

            .offerShipping {
            }

            .per-unit {
                font-size: map-deep-get($cartRadioButtons, scheme, summary, perUnit, fontSize);

                @include media("<tablet-mid") {
                    --perUnitFontSize: 1em;

                    display: flex;
                    flex-direction: column;
                }

                &__item {
                    font-weight: map-deep-get($cartRadioButtons, scheme, summary, perUnit, fontWeight);

                    &--units {
                        @include media("<tablet-mid") {
                        }
                    }

                    &--value {
                        @include media("<tablet-mid") {
                            --perUnitFontWeight: normal;
                        }
                    }
                }
            }
        }
    }

    //- items within Option buttons
    .caption {
    }

    .badge {
        font-size: map-deep-get($cartRadioButtons, scheme, buttons, badgeFontSize);
        font-weight: map-deep-get($cartRadioButtons, scheme, buttons, badgeFontWeight);
        color: map-deep-get($cartRadioButtons, scheme, buttons, badgeColor);
    }

    .saving,
    .shipping {
        font-size: map-deep-get($cartRadioButtons, scheme, buttons, savingFontSize);
        font-weight: map-deep-get($cartRadioButtons, scheme, buttons, savingFontWeight);
        color: map-deep-get($cartRadioButtons, scheme, buttons, savingColor);
    }

    .disclaimer-title {
        margin-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);

        .icon {
            margin-right: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 2.5);
            font-size: 1.5em;
        }
    }

    .disclaimer-box {
        overflow: hidden;
        max-height: 0;
        margin-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);
        padding-right: map-deep-get($cartRadioButtons, structure, generic, gap);
        padding-left: map-deep-get($cartRadioButtons, structure, generic, gap);
        font-size: map-deep-get($cartRadioButtons, scheme, disclaimer, fontSize);
        text-align: left;
        background-color: map-deep-get($cartRadioButtons, scheme, disclaimer, backgroundColor);
        color: map-deep-get($cartRadioButtons, scheme, disclaimer, color);
        transition: all 250ms ease-in-out;
        border-radius: map-deep-get($cartRadioButtons, scheme, disclaimer, borderRadius);

        @include media("<tablet-mid") {
            width: calc(100% + map-deep-get($cartRadioButtons, structure, generic, gap));
            margin-left: calc(-1 * map-deep-get($cartRadioButtons, structure, generic, gap) / 2);
        }

        &.open {
            max-height: 500px;
            padding-top: map-deep-get($cartRadioButtons, structure, generic, gap);
            padding-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);
        }

        a {
            color: map-deep-get($cartRadioButtons, scheme, disclaimer, linkColor);
            text-decoration: underline;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            gap: map-deep-get($cartRadioButtons, structure, generic, gap);
            margin-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);
        }

        &__title {
            display: flex;
            flex: 0 1 100%;
            gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 2);
            user-select: none;
            cursor: pointer;

            .icon {
                visibility: hidden;
                margin-top: -1px;
            }

            &.checked {
                .icon {
                    visibility: visible;
                }
            }
        }

        &__close {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            width: map-deep-get($cartRadioButtons, scheme, disclaimer, checkboxSize);
            height: map-deep-get($cartRadioButtons, scheme, disclaimer, checkboxSize);
            margin-top: calc(-1 * map-deep-get($cartRadioButtons, structure, generic, gap));
            margin-right: calc(-1 * map-deep-get($cartRadioButtons, structure, generic, gap));
            padding: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 2);
            font-size: 0.75em;
            cursor: pointer;
        }

        &__checked {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: map-deep-get($cartRadioButtons, scheme, disclaimer, checkboxSize);
            height: map-deep-get($cartRadioButtons, scheme, disclaimer, checkboxSize);
            border: map-deep-get($cartRadioButtons, scheme, generic, borderWidth)
                map-deep-get($cartRadioButtons, scheme, generic, borderStyle)
                map-deep-get($cartRadioButtons, scheme, disclaimer, checkboxBorderColor);
            border-radius: map-deep-get($cartRadioButtons, scheme, disclaimer, borderRadius);
        }
    }
}

.cart--radioButtons-v01 {
    [class$="__input"] {
        &::before {
        }
    }

    [class$="__label"] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media(">=tablet-mid") {
            --captionFontWeight: bold;
        }

        @include media(">=tablet-mid", "<1075px") {
            --captionFontSize: 0.7em;
        }

        @include media(">=1075px") {
            --captionFontSize: 0.75em;
        }
    }

    .cart {
        &__products {
            --cartGap: 0.625em;

            @include media(">=tablet-mid") {
                margin-bottom: 1em;
            }
        }

        &__product {
            padding: map-deep-get($cartRadioButtons, structure, generic, gap);
            border: map-deep-get($cartRadioButtons, scheme, generic, borderWidth)
                map-deep-get($cartRadioButtons, scheme, generic, borderStyle)
                map-deep-get($cartRadioButtons, scheme, buttons, borderColorInitial);

            @include media("<tablet-mid") {
                --cartGap: 0.35em;

                flex: 1 1 100%;
                width: 100%;
            }

            @include media(">=tablet-mid", "<1075px") {
                --cartGap: 0.4em;
            }

            @include media(">=tablet-mid") {
                display: flex;
                flex: 1 1 33.33%;
                align-items: center;
                gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 1.5);
                width: 33.33%;
                text-transform: uppercase;
                border-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);
            }
        }

        &__input {
            @include media("<tablet-mid") {
                display: none;
            }
        }
    }

    //- Product options and Mode CTAs active state
    .cart__products,
    .subscribe-box {
        & > *:hover {
            @include media(">=tablet-mid") {
                box-shadow: map-deep-get($cartRadioButtons, scheme, buttons, shadow);
            }
        }

        .active {
            @include media("<tablet-mid") {
                color: map-deep-get($cartRadioButtons, scheme, buttons, colorActive);
                background-color: map-deep-get($cartRadioButtons, scheme, buttons, backgroundColorActive);
            }

            @include media(">=tablet-mid") {
                border-color: map-deep-get($cartRadioButtons, scheme, buttons, borderColorActive);
                box-shadow: map-deep-get($cartRadioButtons, scheme, buttons, shadow);
            }

            [class$="__input"] {
                --radioButtonColor: var(--mainColor);

                &::before {
                    width: calc(map-deep-get($cartRadioButtons, scheme, buttons, radioButtonSize) / 2);
                    height: calc(map-deep-get($cartRadioButtons, scheme, buttons, radioButtonSize) / 2);
                }
            }
        }
    }

    .subscribe-box {
        --cartGap: 0.625em;

        justify-content: flex-start;

        @include media("<tablet-mid") {
            flex-direction: column;
            margin-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);
        }

        @include media(">=tablet-mid") {
            margin-bottom: 1em;
        }

        &__default {
            line-height: 1.3;

            @include media("<tablet-mid") {
                --cartGap: 0.35em;

                flex: 1 1 100%;
                gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);
                width: 100%;
                padding-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);
                padding-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);
            }

            @include media(">=tablet-mid") {
                gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 1.5);
                text-transform: uppercase;
                border: map-deep-get($cartRadioButtons, scheme, generic, borderWidth)
                    map-deep-get($cartRadioButtons, scheme, generic, borderStyle)
                    map-deep-get($cartRadioButtons, scheme, buttons, borderColorInitial);
                border-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);
            }

            @include media(">=tablet-mid", "<1075px") {
                --cartGap: 0.4em;

                padding-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2.4);
                padding-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2.4);
            }

            @include media(">=1075px") {
                padding-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 1.5);
                padding-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 1.5);
            }

            &.active {
                @include media("<tablet-mid") {
                    position: relative;
                    --backgroundColorActive: var(--lightBackground);
                    --colorActive: var(--textColor);

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%);
                        width: 100vw;
                        background-color: map-deep-get($cartRadioButtons, scheme, buttons, backgroundColorActive);
                        z-index: -1;
                    }
                }
            }

            .price {
                @include media(">=tablet-mid") {
                    display: none;
                }

                &__country {
                    font-size: 0.75em;
                }
            }
        }
    }

    .subscribe-wrap {
        &__label {
            flex-direction: row;

            @include media("<mobile") {
                --captionFontSize: 0.95em;
            }

            @include media(">=mobile", "<tablet-mid") {
                --captionFontSize: 1em;
            }

            @include media("<tablet-mid") {
                --captionFontWeight: bold;
            }
        }
    }

    .badge {
        @include media("<tablet-mid") {
            --badgeFontSize: 0.88889em;
        }

        @include media(">=tablet-mid", "<1090px") {
            --badgeFontSize: 1em;
        }

        @include media(">=tablet-mid") {
            --badgeColor: red;
        }
    }

    .saving,
    .shipping {
        @include media("<tablet-mid") {
            @at-root .cart--radioButtons-v01 .cart__product.active {
                --savingColor: white;
            }
        }
    }

    .extra {
        .saving,
        .shipping {
            @include media("<tablet-mid") {
                --savingFontSize: 0.88889em;

                display: inline-block;
            }

            @include media(">=tablet-mid") {
                --savingFontSize: 1em;
            }
        }
    }
}

.cart--radioButtons-v02 {
    [class$="__input"] {
        --radioButtonBorderWidth: 1px;

        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-color: map-deep-get($cartRadioButtons, scheme, buttons, radioButtonBackgroundColor);

        @include media('<mobile') {
            --radioButtonSize: 28px;
        }

        @include media('>=mobile') {
            --radioButtonSize: 34px;
        }

        .icon {
            --radioButtonColor: var(--mainColor);

            position: absolute;
            display: none;
            font-size: var(--productIconInputSize, 2.5em);
            color: white;
            fill: transparent;

            @include media('<mobile') {
                --radioButtonSize: 30px;
                --productIconInputSize: 2.25em;
            }
    
            @include media('>=mobile') {
                --radioButtonSize: 34px;
            }
        }
    }

    [class$="__label"] {
        --captionFontWeight: bold;
    }

    .cart {
        &__products {
            --cartGap: 0;

            flex-direction: column;
            margin-bottom: 1.25em;
            border: map-deep-get($cartRadioButtons, scheme, generic, borderWidth)
                map-deep-get($cartRadioButtons, scheme, generic, borderStyle)
                map-deep-get($cartRadioButtons, scheme, buttons, borderColorInitial);
            border-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);

            .active {
                --backgroundColorActive: #{$mainColorLighter};

                background-color: map-deep-get($cartRadioButtons, scheme, buttons, backgroundColorActive);

                [class$="__input"] {
                    --radioButtonColor: #{$colorLight};

                    &::before {
                        width: map-deep-get($cartRadioButtons, scheme, buttons, radioButtonSize);
                        height: map-deep-get($cartRadioButtons, scheme, buttons, radioButtonSize);
                    }

                    .icon {
                        display: inline-block;
                    }
                }
            }
        }

        &__product {
            --cartGap: 1.25em;

            position: relative;
            display: flex;
            align-items: center;
            gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 1.5);
            padding-top: map-deep-get($cartRadioButtons, structure, generic, gap);
            padding-right: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 1.25);
            padding-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 1.25);
            
            text-align: left;

            @include media('<mobile') {
                padding-left: calc(#{map-deep-get($cartRadioButtons, structure, generic, gap)} / 1.5);
            }

            @include media('>=mobile') {
                padding-left: map-deep-get($cartRadioButtons, structure, generic, gap);
            }

            &:hover {
                box-shadow: map-deep-get($cartRadioButtons, scheme, buttons, shadow);
                z-index: 1;
            }

            & + .cart__product {
                border-top: map-deep-get($cartRadioButtons, scheme, generic, borderWidth)
                    map-deep-get($cartRadioButtons, scheme, generic, borderStyle)
                    map-deep-get($cartRadioButtons, scheme, buttons, borderColorInitial);
            }

            &:first-child {
                border-top-right-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);
                border-top-left-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);
            }

            &:last-child {
                border-bottom-right-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);
                border-bottom-left-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);
            }
        }

        &__label {
            --cartGap: .5em;

            flex-grow: 1;
            display: grid;
            // grid-template-areas: "caption retail offer" "perUnit . saving";
            column-gap: map-deep-get($cartRadioButtons, structure, generic, gap);
            row-gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 2);
            grid-template-columns: 2fr 1fr auto;
            grid-template-rows: 1.75fr 1fr;
            align-items: center;

            @include media('<mobile') {
                --captionFontSize: 18px;
            }
    
            @include media('>=mobile') {
                --captionFontSize: 20px;
            }

            @include media('<tablet-mid') {
                grid-template-areas: "caption retail offer" "perUnit . saving";
            }

            @include media('>=tablet-mid') {
                grid-template-areas: "caption saving retail offer" "perUnit . . .";
            }

            .caption {
                grid-area: caption;
                justify-self: start;
            }
            
            .per-unit__item, .offerShipping {
                grid-area: perUnit;
                justify-self: start;
                margin-top: calc(-1.5 * map-deep-get($cartRadioButtons, structure, generic, gap));
            }

            .per-unit__title {
                
            }

            .retail {
                grid-area: retail;
                justify-self: end;
            }
            
            .offer {
                grid-area: offer;
                justify-self: end;
            }
            
            .saving {
                grid-area: saving;
                justify-self: end;
                white-space: nowrap;
            }
        }
    }

    //- Product options and Mode CTAs active state
    .cart__products,
    .subscribe-box {
        & > *:hover {
            @include media(">=tablet-mid") {
            }
        }
    }

    .subscribe-box {
        --cartGap: 1.875em;

        flex-direction: row-reverse;
        margin-bottom: 1em;

        &__default {
            --cartGap: 0.15em;
            --colorInitial: white;
            --backgroundColorInitial: var(--mainBackground);
            --borderColorInitial: var(--mainBackground);

            justify-content: center;
            flex: 1 1 50%;
            text-transform: unset;
            line-height: 1.2;
            background-color: map-deep-get($cartRadioButtons, scheme, buttons, backgroundColorInitial);
            border: map-deep-get($cartRadioButtons, scheme, generic, borderWidth)
                    map-deep-get($cartRadioButtons, scheme, generic, borderStyle)
                    map-deep-get($cartRadioButtons, scheme, buttons, borderColorInitial);
            border-radius: map-deep-get($cartRadioButtons, scheme, generic, borderRadius);

            &.active {
                --backgroundColorActive: var(--mainColor);
                background-color: map-deep-get($cartRadioButtons, scheme, buttons, backgroundColorActive);
                box-shadow: map-deep-get($cartRadioButtons, scheme, buttons, shadow);
            }
        }
    }

    .subscribe-wrap {
        &__label {
            text-align: center;
            
            @include media('<mobile') {
                --captionFontSize: 16px;
            }
    
            @include media('>=mobile') {
                --captionFontSize: 18px;
            }
        }
    }

    .badge {
        position: absolute;
        top: 0;
        left: 0;
        padding-inline: var(--badgeHeight);
        line-height: var(--badgeHeight);

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: calc(100% - var(--badgeHeight) / 2);
            display: inline-block;
            width: var(--badgeHeight);
            height: var(--badgeHeight);
            background-color: inherit;
            transform: skewX(-45deg);
            color: red;
        }

        @include media('<mobile') {
            --badgeFontSize: 0.6em;
            --badgeHeight: 18px;
        }

        @include media('>=mobile') {
            --badgeFontSize: 0.7em;
            --badgeHeight: 20px;
        }
    }

    [data-units="3"] .badge {
        --badgeColor: var(--tertiaryColor);

        background-color: var(--badgeBackground, #{$mainColorDark});
    }

    [data-units="6"] .badge {
        --badgeColor: #{$mainColorDark};

        background-color: var(--badgeBackground, var(--tertiaryColor));
    }

    .per-unit__item, .offerShipping {
        --perUnitFontSize: 0.7em;

        font-size: map-deep-get($cartRadioButtons, scheme, summary, perUnit, fontSize);
    }

    .per-unit__item {
        &--units {
            @include media("<tablet-mid") {
            }
        }

        &--value {
        }
    }

    .retail {
        font-size: map-deep-get($cartRadioButtons, scheme, summary, retail, fontSize);
        text-decoration: map-deep-get($cartRadioButtons, scheme, summary, retail, textDecoration);
        color: map-deep-get($cartRadioButtons, scheme, summary, retail, color);
        
        @include media("<tablet-mid") {
            --retailFontSize: 14px;
        }
        
        @include media(">=tablet-mid") {
            --retailFontSize: 16px;

            margin-right: .25em;
        }
    }
        
    .saving {
        --cartGap: 0.425em;
        --savingFontSize: 0.7em;
        --savingColor: #{$textColor};
        
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: calc(-1 * #{map-deep-get($cartRadioButtons, structure, generic, gap)});
            right: calc(-1 * #{map-deep-get($cartRadioButtons, structure, generic, gap)});
            bottom: calc(-1 * #{map-deep-get($cartRadioButtons, structure, generic, gap)});
            left: calc(-1 * #{map-deep-get($cartRadioButtons, structure, generic, gap)});
            background-color: map-deep-get($cartRadioButtons, scheme, buttons, savingBackgroundColor);
            z-index: -1;
        }

        @include media('>=tablet-mid') {
            margin-right: calc(var(--cartGap) + .25em);
        }
    }
}

.cart {
    .guaranteeBanner {
        margin-top: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 1.5);
        text-align: center;

        &__title {
            margin-bottom: map-deep-get($cartRadioButtons, structure, generic, gap);
            font-size: map-deep-get($cartRadioButtons, scheme, guaranteeBanner, fontSize);
            font-weight: map-deep-get($cartRadioButtons, scheme, guaranteeBanner, fontWeight);
        }

        &__list {
            display: flex;
            align-items: center;
            gap: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 2);

            @include media("<mobile") {
                --cartGap: 1.5em;
            }

            @include media(">=mobile", "<tablet-mid") {
                --cartGap: 2em;
            }

            @include media("<1075px") {
                justify-content: center;
                flex-wrap: wrap;
            }

            @include media(">=1075px") {
                justify-content: space-between;
            }
        }

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 map-deep-get($cartRadioButtons, scheme, guaranteeBanner, itemsSize);
            width: map-deep-get($cartRadioButtons, scheme, guaranteeBanner, itemsSize);
            height: map-deep-get($cartRadioButtons, scheme, guaranteeBanner, itemsSize);
            padding: calc(map-deep-get($cartRadioButtons, structure, generic, gap) / 2);
            font-size: map-deep-get($cartRadioButtons, scheme, guaranteeBanner, itemsFontSize);
            font-weight: map-deep-get($cartRadioButtons, scheme, guaranteeBanner, itemsFontWeight);
            background-color: map-deep-get($cartRadioButtons, scheme, guaranteeBanner, itemsBackgroundColor);
            border-radius: 50%;

            @include media("<mobile") {
                --cartGap: 1em;
            }
        }

        .disclaimer {
            margin-top: 1.5em;
        }
    }

    .benefits-list {
        display: flex;
        flex-wrap: wrap;
        gap: map-deep-get($cartRadioButtons, structure, generic, gap);
        margin-bottom: calc(map-deep-get($cartRadioButtons, structure, generic, gap) * 2);

        &__item {
            flex: 1 1 calc(50% - .5em);
            max-width: calc(50% - .5em);
            padding-left: calc(map-deep-get($cartRadioButtons, scheme, disclaimer, benefitsListFontSize) + .75em);
            font-size: map-deep-get($cartRadioButtons, scheme, disclaimer, benefitsListFontSize);
        }

        .icon {
            display: inline-block;
            margin-right: .25em;
            margin-left: calc((map-deep-get($cartRadioButtons, scheme, disclaimer, benefitsListFontSize) + .25em) * -1);
            font-size: map-deep-get($cartRadioButtons, scheme, disclaimer, benefitsListIconFontSize);
            color: map-deep-get($cartRadioButtons, scheme, disclaimer, benefitsListIconColor);
        }
    }

    .hide-td {
        @include media(">=tablet-mid") {
            display: none;
        }
    }

    .hide-mt {
        @include media("<tablet-mid") {
            display: none;
        }
    }
}
