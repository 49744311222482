@import "default";

.cta-wrap {
    margin: 0 auto;
    text-align: center;
    color: map-get($cta-schema, color);
    background: map-get($cta-schema, wrapperBackgroundColor);

    section & {
        margin-top: 1.25em;
    }

    section + & {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
    }

    [class^="container"] {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--ctaWrapGap, 2em);
    }

    &--sticky {
        position: sticky;
        top: calc(100% - var(--stickyBarHeight));
        left: 0;
        max-width: none;
        width: 100%;
        height: var(--stickyBarHeight);
        z-index: 999;

        &:not(:last-child) {
            & + * {
                margin-top: calc(var(--stickyBarHeight) * -1);
            }
        }

        & ~ *:last-child {
            padding-bottom: calc(var(--stickyBarHeight) + var(--section-inner));
        }
    }

    &__title {
        font-size: map-get($cta-schema, titleFontSize);
        line-height: 1;

        @include media("<mobile") {
            --ctaTitleFontSize: 1em;
        }

        @include media(">=mobile", "<414px") {
            --ctaTitleFontSize: 1.25em;
        }

        @include media(">=414px", "<tablet") {
            --ctaTitleFontSize: 1.5em;
        }
    }

    .btn {
        flex: 1 1 auto;
        max-width: 370px;
        margin: 0;
    }

    .hide-td {
        @include media(">=tablet-mid") {
            display: none;
        }
    }

    .hide-mt {
        @include media("<tablet-mid") {
            display: none;
        }
    }
}

.btn {
    display: inline-block;
    padding: map-get($cta-structure, padding);
    font-family: map-get($cta-schema, fontFamily);
    font-weight: map-get($cta-schema, fontWeight);
    font-size: map-get($cta-schema, fontSize);
    text-align: center;
    white-space: nowrap;
    line-height: 1;
    color: map-get($cta-schema, color);
    background: map-deep-get($cta-schema, bg, initial);
    border-radius: map-get($cta-schema, radius);
    box-shadow: map-get($cta-schema, shadow);
    transition: all 150ms ease-in-out;

    @include media('<mobile') {
        width: 100%;
        padding-inline: .5em;
    }

    &:not([href]) {
        &, &:hover {
            color: map-get($cta-schema, color);
        }
    }

    &.disabled,
    [disabled] {
        filter: grayscale(1);
    }

    &:hover,
    &:visited {
        color: map-get($cta-schema, color);
        text-decoration: none;
    }

    &:hover {
        background: map-deep-get($cta-schema, bg, hover);
    }

    .icon {
        font-size: map-get($cta-schema, iconSize);
    }
}
