// .video-js {
//     .vjs-big-play-button {
//         display: none;
//     }
// } 

$custom-play: (
    rounded-corners: '<svg xmlns="http://www.w3.org/2000/svg" width="80.29" height="90"><path fill="#{rgba($white, .99)}" d="M0 60.25V9.42C0 1.03 5.94-2.4 13.21 1.79l17.61 10.17c7.26 4.19 19.15 11.06 26.41 15.25l17.61 10.17c7.26 4.19 7.26 11.06 0 15.25L57.23 62.79c-7.26 4.19-19.15 11.06-26.41 15.25L13.21 88.21C5.94 92.4 0 88.97 0 80.58V60.25z"/></svg>', //- map-get($custom-play, rounded-corners)
);

.video {
    .video-js {
        // position: absolute; top: 0; left: 0; overflow: hidden;

        .vjs-big-play-button {
            width: 1.5em; height: 1.5em; margin: 0; transform: translate(-50%, -50%);
            background-color: transparent; border: 2px solid; border-radius: 50%;
            font-size: 6em; 
            transform-origin: center;
        }
    }

    //- Used when poster is a transparent image and 1st video frame may be showned bellow it
    &.transparent-poster {
        .vjs-paused:not(.vjs-has-started) {
            video {
                opacity: 0;
            }
        }
    }

    &.custom-play {
        .video-js {
            .vjs-big-play-button {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
                font-size: 5.5em;

                .vjs-icon-placeholder {
                    position: relative;
                    display: block; width: 80px; height: 90px; margin: auto;
                    line-height: 0; transform: scale(0.4);
                    
                    &::before {
                        content: '';
                        mask: url($svg-data + map-get($custom-play, rounded-corners)) no-repeat;
                        -webkit-mask: url($svg-data + map-get($custom-play, rounded-corners)) no-repeat;
                        position: absolute; top: -12%; left: 10%; 
                        transition: all .4s;
                        background-color: $white;
                        border-radius: 0% 50% 50% 0%
                    }
                }

                @include media('hover', 'fine') {
                    &:hover {
                        .vjs-icon-placeholder {
                            &::before {
                                transform: scale(1.075); transition: all .4s;
                            }
                        }
                    }
                }
            }
        }
    }
}
