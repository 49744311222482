.hide {
    display: none !important;

    &--sm {
        @include media('<desktop') {
            display: none !important;
        }
    }

    &--lg {
        @include media('>=desktop') {
            display: none !important;
        }
    }
}

.show {
    display: block !important;

    &--sm {
        @include media('<desktop') {
            display: block !important;
        }
    }

    &--lg {
        @include media('>=desktop') {
            display: block !important;
        }
    }
}

.invisible {
    visibility: hidden !important;
}