@use "sass:math";
@import "variables";

:root {
    --iconMarginTop: 1.5rem;
}

.cart--tabs {
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    text-align: center;
    background-color: $highlight-color;

    body.v03 & {
        padding-top: 2.5rem;
    }
    
    .cart {
        &__image {
            position: relative;
            // margin-bottom: map-get($section-flow, mobile) / 2;
            z-index: 1;

            // Commented out after GH's request of alignment with their page changes
            // &::before {
            //     content: ''; position: absolute; top: -1rem; bottom: 11rem; display: block; width: 100%; z-index: -1;
            //     background-color: $white;
            // }
        }

        &__hero {
            position: relative;
            margin-top: -2.5rem;
            background-size: cover;
            background-position: center;
            
            @include media ("<mobile-mid") {
                background-image: var(--hero-bg-mobile);
            }
            
            @include media (">=mobile-mid") {
                background-image: var(--hero-bg-desktop);
            }

            @include media ("<tablet") {
                min-height: 100vh;
                min-height: calc(var(--app-height) - var(--header-height));
                margin-bottom: map-get($section-flow, mobile) * 2.5;
                padding: 1.8em 0;
            }

            @include media (">=tablet") {
                margin-bottom: map-get($section-flow, mobile) * 4;
                height: 600px;
                padding: 3em 0;
            }

            &--halfScreen {
                min-height: 60vh;
                margin-bottom: map-get($section-flow, mobile) * 2;
            }

            .clickableReviews {
                justify-content: flex-start;
                margin: 0;
                align-items: baseline;
                gap: 0.9em;
                pointer-events: none;
                
                @include media ("<mobile") {
                    font-size: 17px;
                }
                
                @include media (">=mobile", "<desktop") {
                    font-size: 18px;
                }

                @include media (">=desktop") {
                    font-size: 24px;
                }

                &__title {
                    order: 1;
                    color: $white;
                    font-weight: 400;
                    font-size: inherit;
                }

                &__rating {
                    order: 3;
                    color: $white;
                    letter-spacing: 0.58em;
                    width: 7em;
                    
                    @include media ("<desktop") {
                        font-size: 0.89em;
                    }

                    @include media (">=desktop") {
                        font-size: inherit;
                    }
                }

                &__text {
                    order: 2;
                    color: $white;
                    
                    @include media ("<desktop") {
                        font-size: 1.2223em;
                    }

                    @include media (">=desktop") {
                        font-size: inherit;
                    }
                }
            }

            .container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                
                @include media ("<tablet") {
                    max-width: 400px;
                }

                @include media (">=tablet", "<desktop") {
                    max-width: 600px;
                }

                @include media (">=desktop") {
                    max-width: 960px;
                }
            }
        }

        &__hero-title {
            font-weight: 600;
            letter-spacing: -0.1px;
            text-align: left;

            @include media ("<mobile") {
                font-size: 18px;
            }

            @include media (">=mobile", "<tablet") {
                font-size: 22px;
            }

            @include media (">=tablet", "<desktop") {
                font-size: 32px;
            }

            @include media ("<desktop") {
                margin-bottom: 1em;
                line-height: 1.5;
            }

            @include media (">=desktop") {
                font-size: 40px;
                line-height: 1.2;
                margin-bottom: 0.5em;
            }
        }

        &__hero-cta {
            display: block;
            margin-top: 1.4em;
            font-size: 20px;
            color: $darkestGrey;
            font-weight: 600;
            border-radius: 8px;
            background: $white;
            padding: 0.675em 1.9em;
            letter-spacing: normal;
        }

        &__hero-benefits {
            margin-top: 20px;
            color: $white;

            li {
                position: relative;
                padding-left: 25px;
                margin-bottom: 8px;
                font-size: var(--heroListFs, 18px);
                font-weight: 600;
                line-height: 1.3;
                text-align: left;

                @include media(">=tablet") {
                    --heroListFs: 22px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 10px;
                    height: 10px;
                    background-color: $white;
                    border-radius: 80%/ 0 10px 10px 0;
                }
            }
        }

        &__hero-learnMore {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 20px 0;
            font-size: var(--learnMoreFS, 14px);
            color: $white;
            background-color: hsla(calc(var(--mainColor_h) - 5), calc(var(--mainColor_s) + 25%), calc(var(--mainColor_l) + 8%), .4);

            @include media (">=tablet") {
                --learnMoreFS: 18px;
            }

            span {
                padding: 3px 8px;
                border: 1px solid white;
                border-radius: 50%;
            }
        }

        &__imgsrc {
            width: 90%;
            max-width: 25rem;
        }

        &__icon {
            width: 100%;
            height: 4.5rem;
            font-size: 4rem;
            line-height: 1;
            text-align: center;

            .icon {
                color: rgba($white, 0.75);
                stroke: currentColor;
                animation: bounce-with-distortion 2s infinite cubic-bezier(0.28, 0.84, 0.42, 1);
            }
        }

        &__title {
            margin-bottom: map-get($section-flow, mobile) / 2;
        }

        &__details {
            margin-bottom: 1.5rem;
            padding-top: 2rem;
            padding-bottom: map-get($section-inner, mobile);
            font-size: 2rem;
            background-color: $white;
            border-radius: 2.5rem;

            .cart {
                &__icon {
                    --iconMarginTop: 15px;
                }
            }

            .subscribe-box {
                row-gap: 2em;
                margin-top: 2rem;
                margin-bottom: 2rem;
                padding-left: 2rem;
                padding-right: 2rem;
            }

            .benefits-list__item,
            .purchaseMode__disclaimer {
                color: var(--textColor);
            }

            .benefits-list__item {
                line-height: 1.2;

                .icon {
                    color: $mainColorDark;
                }
            }

            .purchaseMode__disclaimer {
                margin-top: 0;
            }

            &:first-child {
                margin-top: 4.5rem
            }
        }

        &__products {
            margin-bottom: 0.3rem;
            padding-left: 2rem;
            padding-right: 2rem;

            body.v03 & {
                display: flex;
                flex-direction: column;
            }
        }

        &__product {
            margin-bottom: map-get($section-flow, mobile) / 2;
            padding-top: map-get($product-cta, block-padding);
            padding-bottom: map-get($product-cta, block-padding);
            line-height: 1;
            background-color: map-get($product-cta, initial-bg);
            border: map-get($product-cta, border) map-get($product-cta, initial-border);
            border-radius: map-get($product-cta, radius);
            font-size: var(--quantityButtonFontSize);
            cursor: pointer;

            &[data-units="3"] {
                body.v03 & {
                    order: -1;
                }
            }

            &.active {
                color: map-get($product-cta, active-color);
                background-color: map-get($product-cta, active-bg);
                border-color: map-get($product-cta, active-border);
                cursor: default;
            }
        }

        &__summary {
            position: relative;
            margin-bottom: 1.6rem;
            padding-left: 2rem;
            padding-right: 2rem;

            &--reorder {
                display: flex;
                flex-direction: column;

                .cart__current {
                    order: 1;
                    margin-top: 0.2em;
                    margin-bottom: 1.5rem;
                    font-size: 56px;
                }

                .cart__retail, .cart__instant-savings {
                    margin-bottom: 0.6666em;
                    font-size: 18px;
                    letter-spacing: normal;

                    @include media("<mobile") {
                        font-size: 16px;
                    }
                }

                .cart__retail {
                    order: 2;
                }

                .cart__instant-savings {
                    order: 3;
                }

                .cart__shipping {
                    order: 4;
                    font-size: 14px;
                }

            } 
        }

        &__retail {
            letter-spacing: -0.12px;
            color: $retail-color;
            font-size: var(--retailFontSize);

            span {
                text-decoration: line-through;
            }
        }

        &__current {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
            font-size: var(--currentPriceFontSize);
            font-weight: $font-weight-bold;
            color: $current-price-color;
        }

        &__current-country {
            font-size: 0.875em;
        }

        &__unit-price, &__instant-savings {
            margin-bottom: 0.8rem;
            color: $unit-price-color;
            font-size: var(--perUnitPriceFontSize);

            @include media("<mobile") {
                letter-spacing: 0.05rem;
            }
        }

        &__shipping {
            max-width: 70%;
            margin: 0 auto;
            font-size: var(--shippingFontSize);
            color: $shipping-color;
        }

        &__badge-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);
            font-size: 1rem;
            line-height: 1.4;
            letter-spacing: 1px;
            color: map-get($badge, color);

            body.v03 .cart__badge--unit3 & {
                font-size: 0.85rem;
                white-space: nowrap;
            }
        }

        &__subscribe {
            position: relative;
            margin-bottom: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;

            .cart {
                &__label {
                    margin-bottom: 1.6rem;
                    font-size: 0;
                    font-weight: $font-weight-bold;
                    line-height: map-get($checkbox, size);
                    letter-spacing: 0.05rem;
                    color: $agreement-color;
                    cursor: pointer;

                    span {
                        // display: inline-block;
                        font-size: 1.4rem;
                    }
                }

                &__checkbox {
                    display: inline-block;
                    width: map-get($checkbox, size);
                    height: map-get($checkbox, size);
                    margin-right: 0.5rem;
                    line-height: map-get($checkbox, size);
                    border: map-get($checkbox, border);
                    vertical-align: top;
                    border-radius: map-get($checkbox, radius);
                    box-shadow: map-get($checkbox, shadow);

                    .icon {
                        display: none;
                    }

                    &.checked {
                        .icon {
                            display: block;
                            color: map-get($checkbox, tick-color);
                            stroke: currentColor;
                        }
                    }
                }
            }

            &.hide {
                & + .btn {
                    margin-top: #{map-get($section-flow, mobile)};
                }
            }
        }

        &__mode {
            display: flex;
            justify-content: space-between;
            font-size: 2rem;
            line-height: map-get($mode-cta, line-height);

            .cart__mode-cta {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        //- susbribe-mode CTAs
        &__mode-cta {
            width: calc(50% - 0.5rem);
            padding: map-get($product-cta, block-padding) #{map-get($product-cta, block-padding) / 2};
            letter-spacing: map-get($mode-cta, letter-spacing);
            color: map-get($mode-cta, initial-color);
            background-color: map-get($mode-cta, initial-bg);
            border-radius: map-get($mode-cta, radius);
            cursor: pointer;

            .cart__mode--outline & {
                border: 3px solid transparent;
            }

            &.active {
                color: map-get($mode-cta, active-color);
                background-color: map-get($mode-cta, active-bg);
                cursor: default;

                .cart__mode--outline & {
                    border-color: #3dbf1d;
                }
            }

            small {
                font-size: 12px;
                margin-top: 5px;
            }
        }

        .btn {
            body.v03 & {
                min-width: 23rem;
            }

            &--wider {
                body.v03 & {
                    min-width: 25rem;
                }
            }
        }

        &__ctaNote {
            margin-top: 1.25em;
            margin-bottom: 0.5em;
            font-size: var(--shippingFontSize);
            letter-spacing: normal;

            &--green {
                --shippingFontSize: 16px;

                &, .icon {
                    color: $success;
                }

                .icon {
                    margin-top: -3px;
                    font-size: 2em;
                }
            }
        }
    }

    &.cart--stylingBg {
        position: relative;
        background-color: transparent;
        background-repeat: no-repeat;

        @include media("<tablet") {
            background: {
                image: var(--header_left_bg_m), var(--header_right_bg_m);
            }
        }

        @include media(">=tablet") {
            background-image: var(--header_left_bg_d), var(--header_right_bg_d);
        }

        @include media(">=tablet", "<desktop") {
            @include media("portrait") {
                background: {
                    size: auto 75%;
                    position: -10vw 3vh, 82vw 3vh;
                }
            }

            @include media("landscape") {
                background: {
                    size: auto 65%;
                    position: -8vw 8vh, 88vw 12vh;
                }
            }
        }

        @include media(">=desktop") {
            background: {
                size: auto 75%;
                position: -5vw 80px, 91vw 100px;
            }
        }

        &-v01 {
            @include media("<tablet") {
                background: {
                    size: 45%, 35%;
                    position: -31vw -8vh, 79vw 28vh;
                }
            }
        }

        &-v02 {
            @include media("<tablet") {
                background: {
                    size: 50%, 40%;
                    position: -24vw 8vh, 74vw 16vh;
                }
            }
        }

        & > * {
            position: relative;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        &::before {
            height: 100%;
            background: {
                repeat: no-repeat;
                position: center top;
                size: 100% auto;
                attachment: fixed;
            }
            z-index: -1;

            @include media("<tablet") {
                background-image: var(--header_bg_m);
            }

            @include media(">=tablet") {
                background-image: var(--header_bg_d);
            }
        }

        &::after {
            height: 80%;
            background-image: linear-gradient(to bottom, rgba(69, 81, 109, 0), rgba(69, 81, 109, 0.6));
            z-index: -1;
        }

        .cart {
            &__mainTitle {
                font-size: var(--cartMainTitle-fs, 20px);
                letter-spacing: normal;
                margin-bottom: 1.25em;

                &--v01 {
                    font-weight: $font-weight-bold;
                    color: $mainColorDark;
                }

                &--v02 {
                    --cartMainTitle-fs: 26px;
                    font-weight: normal;
                    color: #45516d;
                    padding-left: 5px;
                    padding-right: 5px;

                    span {
                        font-size: 0.923em;
                    }

                    @include media("<mobile") {
                        --cartMainTitle-fs: 22px;
                    }

                    @include media("<tablet") {
                        span {
                            letter-spacing: -0.04em;
                        }
                    }
                }

                &--v03,
                &--DynamicHL {
                    font-weight: $font-weight-bold;
                    color: $mainColorDark;
                    margin-bottom: 1.7em;
                }

                &--v03 {
                    max-width: 90%;
                    
                    @include media("<mobile") {
                        --cartMainTitle-fs: 19px;
                    }

                    @include media(">=mobile", "<tablet") {
                        --cartMainTitle-fs: 22px;
                    }

                    @include media('>=tablet') {
                        --cartMainTitle-fs: 28px;
                    }
                }

                &--DynamicHL {
                    display: flex;
                    flex-direction: column;

                    @include media("<mobile") {
                        --cartMainTitle-fs: 20px;
                    }

                    @include media(">=mobile") {
                        --cartMainTitle-fs: 24px;
                    }

                    span {
                        display: block;
                    }

                    #swapText {
                        margin-bottom: .25em;
                        font-size: 1.33em;
                        color: var(--CTA);
                    }

                    .fadeIn {
                        animation-name: appear;
                        animation-duration: 0.5s;
                    }
            
                    .fadeOut {
                        animation-name: disappear;
                        animation-duration: 0.5s;
                    }
                }

                &--DynamicHLReverse {
                    flex-direction: column-reverse;

                    #swapText {
                        margin-top: .25em;
                        margin-bottom: 0;
                    }
                }

                &--withBackground {
                    max-width: unset;
                    padding: 0.75em;
                    margin-top: -2.5rem;
                    margin-bottom: 2em;
                    font-size: 24px;
                    background: $mainColorLight;
                }
            }

            &__image {
                // margin-bottom: 5rem;
            }

            &__imgsrc {
                width: 190px;
            }

            &__icon {
                margin-block: var(--iconMarginTop) 2rem;

                .icon {
                    color: $mainColorDark;
                    stroke: none;
                }
            }

            &__title {
                color: $mainColorDark;

                &--v01 {
                    margin-bottom: 24px;
                    color: $darkGrey;
                    font-size: 18px;
                    letter-spacing: normal;
                }
            }
        }
    }

    &.cart--hasBenefitsCarousel {
        &::after {
            height: 100%;
        }
    }

    .purchaseMode {
        margin-bottom: 1.6rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.8rem;
        letter-spacing: normal;
        cursor: pointer;

        &[data-subscribe="subscribe"] {
            .icon--checkbox {
                display: block;
            }
        }

        &__label {
            position: relative;
            justify-content: center;
        }

        &__checkbox {
            margin-right: 0.5em;
            color: $mainColorDark;
        }

        &__text {
            font-weight: bold;
        }

        &__subscriptionNote {
            font-size: var(--shippingFontSize);
            color: var(--secondaryColor);
        }
    }

    .subscribe-box {
        .cart {
            &__label {
                @include user-select;
                position: relative;
                display: flex; 
                justify-content: center;
                align-items: center;
                font-size: 1.4rem;
                font-weight: bold;
                letter-spacing: normal;
                cursor: pointer;

                &--hasPulsingBorder {
                    padding: 6px;
                    border-radius: 8px;
                    transition: 0.3s;
                    animation: pulse 1s infinite;

                    @include media ("<mobile") {
                        font-size: 1.2rem;
                        letter-spacing: inherit;
                    }

                    @include media (">=tablet") {
                        font-size: 1.8rem;
                    }
                }
            }
    
            &__checkbox {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.35em;
                min-width: 1.35em;
                height: 1.35em;
                margin-right: .5em;
                font-size: 1em;
                color: $mainColorDark;
                background-color: hsl(0deg,0%,100%);
                border: 1px solid;
                border-radius: 4px;
                border-color: currentColor;

                &.checked {
                    .icon--checkbox {
                        display: block;
                    }
                }

                &.alert{
                    border-color: $red;
                }
            }
        }

        &__alert {
            display: none;
            font-size: 1.2rem;
            color: $red;

            &.show {
                display: block;
            }
        }
    }

    .cart__disclaimer,
    .subscribe-box__disclaimer {
        font-size: 1rem;
        line-height: 1.2;
        letter-spacing: -0.1px;

        a {
            line-height: inherit;
            letter-spacing: inherit;
        }
    }

    .subscribe-box__disclaimer {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: auto;
        max-height: 0;
        margin-bottom: 0;
        margin-inline: auto;
        transition: all 250ms ease-in-out;

        &--open {
            max-height: 500px;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .purchaseMode__disclaimer {
            display: block;
        }
    }

    .benefits-list {
        --grid-gutter-width: 15px;

        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 0.5em;
        row-gap: 1em;
        max-width: var(--bnefitsMaxWidth, 320px);
        margin-inline: auto;

        @include media(">=tablet") {
            --bnefitsMaxWidth: 330px;
        }

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            font-size: var(--benefitsList-fs, 12px);
            font-weight: bold;
            text-align: left;
            letter-spacing: normal;
            line-height: 1.2;
            color: $white;
            flex: 1 1 calc(50% - 0.5em);

            .icon {
                margin-right: 0.5em;
                color: inherit;
                stroke: currentColor;
            }
        }
    }
}

.cart--whiteBg {
    background: $white;

    .cart {
        &__title {
            color: $mainColorDark;
        }

        &__details {
            box-shadow: 0px 1px 4px $grey;
            border-radius: 8px;
            padding-bottom: 3rem;
        }
    }
}

.cart__badge {
    position: absolute;
    top: 50%;
    left: -2.2rem;
    transform: translateY(-55%);

    body.v03 & {
        top: 40%;

        &--second {
            top: 95%;
        }
    }

    &--default,
    &--second {
        display: none;
        
        &[data-badge1] {
            display: block;
        }

        &:not([data-badge1]) {
            .cart--unit1.cart--tabs & {
                display: none;
            }
        }

        &[data-badge3] {
            display: block;
        }

        &:not([data-badge3]) {
            .cart--unit3.cart--tabs & {
                display: none;
            }
        }

        &[data-badge6] {
            display: block;
        }

        &:not([data-badge6]) {
            .cart--unit6.cart--tabs & {
                display: none;
            }
        }
    }

    &--long {
        left: -1.3rem;
        
        .cart__badge-text {
            font-size: 1.2rem;
            width: 100%;
            letter-spacing: normal;
        }
    }

    .cart--unit3.cart--tabs & {
        display: block;

        &--second {
            display: block;
            letter-spacing: -0.01em;
        }
    }

    .icon {
        font-size: 8.4rem;

        body.v03 & {
            font-size: 8.6rem;
        }
    }
}

.icon--badge {
    fill: map-get($badge, fold-bg);

    .cart--unit1 .cart__details--subscribe &,
    .cart--unit3 & {
        color: map-get($badge, seller-bg);
    }

    .cart--unit6 & {
        color: map-get($badge, value-bg);
    }

    .cart__badge--blue & {
        color: map-get($badge, seller-bg);
    }

    .cart__badge--long & {
        color: map-get($badge, green-bg);
    }

    .cart--unit1 .cart__badge--long & {
        color: map-get($badge, grey-bg);
    }
}

.reviews-amount {
    &__title {
        margin-bottom: 7px;
        font-size: 24px;
        font-weight: 600;
        color: $darkGrey;
        letter-spacing: 0.1px;

        @include media("<tablet") {
            font-size: 16px;
        }
    }

    &__stars {
        display: inline-block;
        max-width: 150px;
        width: auto;
        margin-right: 10px;
        overflow: hidden;
        letter-spacing: 0.2em;
        font-size: 27px;
        color: var(--ratingColor);

        @include media("<tablet") {
            font-size: 23px;
            max-width: 125px;
        }
    }

    &__stars-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    &__text {
        font-size: 16px;
    }
}

.clickableReviews {
    display: flex;
    margin: 0 auto 30px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: inherit;

    @include media (">=mobile") {
        font-size: 16px;
    }

    &__title {
        color: $darkGrey;
        font-weight: 700;

        @include media("<tablet") {
            font-size: 16px;
        }

        @include media(">=tablet") {
            font-size: 24px;
        }
    }

    &__text {
        font-weight: 700;
    }

    &__cta {
        color: var(--testimonialsBannerBackgroundColor);

        &--hasArrow {
            &:after {
                content: "\f078";
                width: 20px;
                height: 20px;
                font-family: 'FontAwesome';
                margin-left: 1em;
            }
        }
    }

    .rating {
        font-size: 20px;
    }

    &--column {
        flex-direction: column;

        .rating {
            @include media(">=tablet") {
                font-size: 24px;
            }
        }
    }

    &--aboveTitle {
        margin-bottom: 15px;

        & + .cart__mainTitle.cart__mainTitle--v03 {
            margin-bottom: 1em;
        }
    }
}

.unitsSoldBanner {
    padding-right: 2rem;
    padding-left: 2rem;

    &__wrapper {
        padding: $grid-gutter-width / 2 $grid-gutter-width * 1.5;
        font-size: 12px;
        background-color: $white;
        color: var(--mainColor);
        border: 2px solid $mainColorLight;
        border-radius: 4px;
    }

    &__content {
        display: block;
        font-weight: bold;

        &--lg {
            font-size: 2.92em;
        }
    }

    &--topCart {
        display: inline-flex;
        justify-content: center;
        margin-bottom: $grid-gutter-width * 1.5;
    }
    
    &--bottomCta {
        margin-top: $grid-gutter-width / 1.5;
    }
}
        