@import 'variables';

.footer {
    font-size: 1.2rem; text-align: center;

    .container {
        max-width: 76rem;
    }

    &__logo {
        display: flex; justify-content: center; align-items: center; margin-bottom: 3.5rem;
    }

    &__links {
        margin-bottom: 3rem; padding-right: .5rem; padding-bottom: 2 * map-get($section-inner, mobile); padding-left: .5rem;
        border-bottom: 1px solid $black;
    }

    &__disclaimer {
        min-width: 290px;
        margin-inline: auto;

        @include media('<tablet') {
            width: 100%;
        }

        @include media('>=tablet') {
            width: 80%;
        }

        p {
            line-height: 1.16667; letter-spacing: .1rem;

            &.disclaimer {
                font-size: 11px; margin-bottom: 1em;
            }
        }
    }

    &__wrap {
        margin-block: 1em;
        padding: 1em;
        border: 1px solid rgba(0, 0, 0, .25);
    }

    .icon {
        font-size: 3.5rem; color: $footer-logo-color;
    }

}