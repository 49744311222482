$cart: (
    title: (
        color: $mainColorLight, //- map-deep-get($cart, title, color)
    ),
    bg: (
        start-color: #3e3f43, //- map-deep-get($cart, bg, start-color)
        end-color: #747780, //- map-deep-get($cart, bg, end-color)
    ),
);

$card: (
    default-size: 18px, //- map-get($card, default-size)
    title: 24px, //- map-get($card, title)
    amount: 56px, //- map-get($card, amount)
    shadow: (
        default: 3px 3px 6px 0 rgba(0, 0, 0, 0.16), //- map-deep-get($card, shadow, default)
    ),
    outer-space: (
        mobile: 20px, //- map-deep-get($card, outer-space, mobile)
        tablet: 18px, //- map-deep-get($card, outer-space, tablet)
        desktop: 15px //- map-deep-get($card, outer-space, desktop)
    ),
    inner-space: 6px, //- map-get($card, inner-space)
);

$purchase-mode: (
    height: (
        mobile: 16px, //- map-deep-get($purchase-mode, height, mobile)
        tablet: 30px, //- map-deep-get($purchase-mode, height, tablet)
        desktop: 30px, //- map-deep-get($purchase-mode, height, desktop)
    ),
    color: $white, //- map-get($purchase-mode, color)
    bg: (
        color:  $mainColorLight, //- map-deep-get($purchase-mode, bg, color)
    ),
    border: (
        size: 2px, //- map-deep-get($purchase-mode, border, size)
        color: $mainColorLight, //- map-deep-get($purchase-mode, border, color)
    ),
);

$stamp: (
    font: (
        weight: 400, //- map-deep-get($stamp, font, weight)
        size-desktop: 22px, //- map-deep-get($stamp, font, size-desktop)
        size-tablet: 20px, //- map-deep-get($stamp, font, size-tablet)
        size-mobile: 14px, //- map-deep-get($stamp, font, size-mobile)
    ),
    color: (
        //price: lighten($mainColorLight, 10), //- map-deep-get($stamp, color, price)
        price:  hsl(calc(var(--secondaryColor_h) + 5), calc(var(--secondaryColor_s) - 26%), calc(var(--secondaryColor_l) - 6%)), //- map-deep-get($stamp, color, price)
        seller: $mainColorLight, //- map-deep-get($stamp, color, seller)
        //value: darken($mainColorLight, 10), //- map-deep-get($stamp, color, value)
        value: hsl(calc(var(--secondaryColor_h) + 5), calc(var(--secondaryColor_s) - 26%), calc(var(--secondaryColor_l) - 6%)), //- map-deep-get($stamp, color, value)
    ),
    size: (
        desktop: 90px, //- map-deep-get($stamp, size, desktop)
        tablet: 70px, //- map-deep-get($stamp, size, tablet)
        mobile: 50px //- map-deep-get($stamp, size, mobile)
    )
);

:root {
    --header_bg_d: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/header_bg.jpg');
    --header_bg_m: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/header_bg_m.jpg');

    --header_left_bg_d: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_left_d.png');
    --header_right_bg_d: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_right_d.png');
    --header_left_bg_m: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_left_m.png');
    --header_right_bg_m: url('https://dtjlhiahlv53j.cloudfront.net/assets/drc/v02/leaves_right_m.png');
}