.featuredOn {
    overflow: hidden;

    &__title {
        margin-bottom: 1em;
        font-weight: 700;
        text-align: center;
        font-size: var(--featuredOnTitleFontSize);

        &::after {
            display: none;
        }
        
        @include media("<mobile") {
            --featuredOnTitleFontSize: 14px;
        }
        
        @include media(">=mobile", "<desktop") {
            --featuredOnTitleFontSize: 18px;
        }
        
        @include media ("<tablet") {
            margin-bottom: 1.4em;
        }

        @include media(">=desktop") {
            --featuredOnTitleFontSize: 24px;
        }
    }

    &__logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__image {
        width: auto;
        margin: 0;
    }

    .disclaimer {
        display: block;
        margin: 4.584em auto 0;
        text-align: center;
        line-height: 1.4;
        font-size: 12px;
        max-width: 400px;

        @include media("<tablet") {
            margin: 3em auto 0;
        }
    }

    .slick-track {
        @include media("<tablet-mid") {
            display: flex;
            align-items: center;
        }
    }

    &--v01 {
        margin-top: 2.2em;
        padding: 1em 0 1.8em;
        background-size: cover;
        background-image: var(--featuredOnBg_image);
        background-position: center;

        .featuredOn__title {
            color: var(--textColor);
        }

        .featuredOn__logos {
            gap: 2.5em;

            @include media("<tablet-mid") {
                margin-left: -$grid-gutter-width / 2;
                margin-right: -$grid-gutter-width / 2;
            }
        }

        .featuredOn__logosItem {
            @include media("<tablet-mid") {
                display: flex!important;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 0 1.325em;
            }
        }

        .featuredOn__image {
            @include media("<tablet") {
                max-height: 50px;
            }
    
            @include media(">=tablet", "<tablet-mid") {
                max-height: 70px;
            }
    
            @include media("<tablet-mid") {
                margin: 0 auto;
            }
    
            @include media(">=tablet-mid", "<desktop-mid") {
                max-height: 50px;
            }
    
            @include media(">=desktop-mid") {
                max-height: 70px;
            }
    
            &--NewsdayLogo {
                @include media("<tablet-mid") {
                    max-width: 200px;
                }
            }
        }
        
        [class^="container"] {
            max-width: 1040px;
        }

        .disclaimer {
            color: $disclaimer;
        }
    }

    &--v02 {
        margin-top: 0;
        padding: 2.5em 0;
        background: $azure;

        &__title {
            color: var(--titleColor);
        }

        &__logos {
            padding: 0 6.4em;
        }

        .featuredOn__logos {
            @include media(">=mobile", "<desktop") {
                max-width: 350px;
                margin-left: auto;
                margin-right: auto;
            }

            @include media(">=tablet") {
                margin-top: 2.5em;
            }

            @include media("<desktop") {
                gap: 1.5em;
            }

            @include media(">=desktop") {
                gap: 2.5em;
            }
        }

        .featuredOn__image {
            height: 70px;
            
            @include media("<mobile") {
                max-width: 100px;
                max-height: 40px;
            }

            @include media(">=mobile", "<desktop") {
                max-width: 120px;
                max-height: 50px;
            }

            @include media(">=desktop") {
                max-width: 160px;
                max-height: 70px;
            }

            &--extraLogo {
                @include media("<mobile") {
                    max-width: 90px;
                }

                @include media(">=mobile", "<desktop") {
                    max-width: 110px;
                }

                @include media(">=desktop") {
                    max-height: 50px;
                    max-width: 150px;
                }

            }

            &--LifeStyleLogo {
                @include media(">=desktop") {
                    max-height: 50px;
                }
            }

            &--NewsdayLogo {
                @include media("<mobile") {
                    max-width: 120px;
                }

                @include media(">=mobile", "<desktop") {
                    max-width: 140px;
                }

                @include media(">=desktop") {
                    max-width: 180px;
                }

            }

            &--EntertainmentTonightLogo {
                @include media("<mobile") {
                    max-height: 33px;
                }

                @include media(">=mobile", "<desktop") {
                    max-height: 40px;
                }

                @include media(">=desktop") {
                    max-height: 60px;
                }

            }
        }

        .featuredOn__title {
            letter-spacing: 0;
        }

        [class^="container"] {
            max-width: 620px;
        }

        .disclaimer {
            color: var(--titleColor);
        }
    }
}