//- Fades related animations
//- Blink
@keyframes blink {
    0%   { opacity: 1; }
    50%  { opacity: 0; }
    100% { opacity: 1; }
}
@-webkit-keyframes blink {
    0%   { opacity: 1; }
    50%  { opacity: 0; }
    100% { opacity: 1; }
}
@-moz-keyframes blink {
    0%   { opacity: 1; }
    50%  { opacity: 0; }
    100% { opacity: 1; }
}

//- Appear
@keyframes appear {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-webkit-keyframes appear {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-moz-keyframes appear {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

//- Disappear
@keyframes disappear {
    0%   { opacity: 1; }
    100% { opacity: 0; }
}
@-webkit-keyframes disappear {
    0%   { opacity: 1; }
    100% { opacity: 0; }
}
@-moz-keyframes disappear {
    0%   { opacity: 1; }
    100% { opacity: 0; }
}

//- Appear Hammer
@keyframes appear-hammer {
    0%   { opacity: 0; }
    50%  { opacity: 1; }
    60%  { opacity: 1; }
    100% { opacity: 0; }
}
@-webkit-keyframes appear-hammer {
    0%   { opacity: 0; }
    50%  { opacity: 1; }
    60%  { opacity: 1; }
    100% { opacity: 0; }
}
@-moz-keyframes appear-hammer {
    0%   { opacity: 0; }
    50%  { opacity: 1; }
    60%  { opacity: 1; }
    100% { opacity: 0; }
}

//- Appear-delay-disappear
@keyframes fade-in-out-delay {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    20%, 80% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
}
@-webkit-keyframes fade-in-out-delay {
    0% {
        opacity: 0;
        transform: scale(0);
      }
    20%, 80% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}
@-moz-keyframes fade-in-out-delay {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    20%, 80% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

//- Blink Cursor
@keyframes blink-cursor {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink-cursor {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink-cursor {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}

//- Pulse
@-webkit-keyframes pulse {
    0% {
        border: 2px solid $lightGreen;
    }

    50% {
        border: 2px solid transparent;
    }

    100% {
        border: 2px solid $lightGreen;
    }
}

@keyframes pulse {
    0% {
        border: 2px solid $lightGreen;
    }

    50% {
        border: 2px solid transparent;
    }

    100% {
        border: 2px solid $lightGreen;
    }
}

@-moz-keyframes pulse {
    0% {
        border: 2px solid $lightGreen;
    }

    50% {
        border: 2px solid transparent;
    }

    100% {
        border: 2px solid $lightGreen;
    }
}