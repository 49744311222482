//- Defining all colors

//- All named colors used on common elements and components
$white: hsl(0, 0%, 100%);
$light-gray: #eee;
$lightPink: #EAACA0;
$silver: #b2b2b2;
$black: #000;
$shadow-blue: #7d92ab;
$grey: #9A9B9C;
$darkGrey: #707070;
$darkestGrey: #484747;
$cello: hsl(210, 47%, 25%); //#22405e
$astronaut: hsl(212, 48%, 31%);//#2a4e76
$azure:        hsl(215, 50%, 43%); //#3663a4
$lapis-lazuli: hsl(217, 49%, 49%); //#4070bb $mainColorDark
$mariner: #2377da; 
$secondaryColor: hsl(212, 73%, 59%); //#4990e2
$boston-blue: #3995a1;
$malibu: hsl(192, 91%, 73%); //#7bdff9
$red: #ff0000;
$pumpkin: hsl(25, 99%, 55%); // fe7a1b
$orange: hsl(20, 99%, 55%); //#fe671b
$tree-poppy: #f69220;
$yellow-sunshine: #fcf101;
$badgeGreen: #36A44C;
$badgeGrey: #8B8B8B;

$slimyGreen: #16A41B;
$lightGreen: #B9D536;

:root {
    --mainColor: hsl(222, 22%, 35%); //- #45516D
    --mainColor_h: 222;
    --mainColor_s: 22%;
    --mainColor_l: 35%;

    --secondaryColor: hsl(212, 73%, 59%); //- #B8D436
    --secondaryColor_h: 212;
    --secondaryColor_s: 73%;
    --secondaryColor_l: 59%;

    --titleColor: hsl(0, 0%, 100%); //- #292929
    --titleColor_h: 0;
    --titleColor_s: 0%;
    --titleColor_l: 100%;

    --textColor: hsl(0, 0%, 0%); //- #292929
    --textColor_h: 0;
    --textColor_s: 0%;
    --textColor_l: 0%;

    --ratingColor: hsl(48, 100%, 50%); //- #FF9E32
    --ratingColor_h: 48;
    --ratingColor_s: 100%;
    --ratingColor_l: 50%;

    --CTA: hsl(20, 99%, 55%); //- #FECA55
    --CTA_h: 20;
    --CTA_s: 99%;
    --CTA_l: 55%;
}

//- common elements colors
$highlight-color: $malibu;

$title-color: $white;
$bannerTitle-color: #22376e;
$text-color: var(--textColor);

$disclaimer: $grey;

$mainColor: var(--mainColor); //hsl(222, 22%, 35%); //#45516D
$mainColorLight: hsl(calc(var(--mainColor_h) - 5), calc(var(--mainColor_s) + 25%), calc(var(--mainColor_l) + 8%)); //#3a61a0 hsl(217, 47%, 43%);
$mainColorDark: hsl(calc(var(--mainColor_h) - 5), calc(var(--mainColor_s) + 27%), calc(var(--mainColor_l) + 14%)); 
$secondaryColor: var(--secondaryColor);

$accordion-title: $mainColorDark;
$arrows: $mainColorDark;
$icons-main-color: $shadow-blue;

$rating-color: var(--ratingColor);

$cta : var(--CTA);
$ctaDark : hsl(calc(var(--CTA_h) + 5),var(--CTA_s), var(--CTA_l) );
$ctaLight : hsl(calc(var(--CTA_h) + 24),calc(var(--CTA_s) + 1%), calc(var(--CTA_l) - 5%));
$ctaLight2 : hsl(calc(var(--CTA_h) + 19),calc(var(--CTA_s) - 16%), calc(var(--CTA_l) - 1%));

$success: $slimyGreen;

// sc_cartRadioButtons
$textColorLight: var(--textColor);
$colorLight: hsl(145, 50%, 50%);
$colorLightOpacity: hsla(var(--textColor_h), var(--textColor_s), calc(var(--textColor_l) + 73%), .15);
$button-shadow: 0 2px 7px rgba(0, 0, 0, 0.28);
$mainColorLighter: hsla(145, 50%, 50%, 0.1);
$textColor: $text-color;