//- Schema related animations
$highlight-color: #7bdff9;

//- Background-color on tap
@keyframes bgColor-on-tap {
    0%   { background-color: rgba($highlight-color, 100%); }
    50%  { background-color: rgba($highlight-color, 100%); }
    100% { background-color: rgba($highlight-color, 0); }
}
@-webkit-keyframes bgColor-on-tap {
    0%   { background-color: rgba($highlight-color, 100%); }
    50%  { background-color: rgba($highlight-color, 100%); }
    100% { background-color: rgba($highlight-color, 0); }
}
@-moz-keyframes bgColor-on-tap {
    0%   { background-color: rgba($highlight-color, 100%); }
    50%  { background-color: rgba($highlight-color, 100%); }
    100% { background-color: rgba($highlight-color, 0); }
}