@import "default";
/*!
 * sc_popscreen
 */
.popscreen {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-block: 0;
    background-color: map-get($popscreen, backgroundColor);

    [class^="container"] {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    //- Popscreen Header
    &__header {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-block: 1em .5em;

        [class^="container"] {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
        }
    }

    &__logo {
        display: flex;
        align-items: center;

        @include media('<tablet') {
            width: map-deep-get($popscreen, logo-width, mobile);   
            font-size: map-deep-get($popscreen, logo-width, mobile);   
        }

        @include media('>=tablet', '<desktop') {
            width: map-deep-get($popscreen, logo-width, tablet);   
            font-size: map-deep-get($popscreen, logo-width, tablet);   
        }

        @include media('>=desktop') {
            width: map-deep-get($popscreen, logo-width, desktop);   
            font-size: map-deep-get($popscreen, logo-width, desktop);   
        }

        .icon {
            height: map-get($popscreen, logo-height);
        }
    }

    &__close-btn {
        display: flex;
        align-self: flex-start;            
        color: inherit;
        font-size: var(--popscreen-closeBtn-fs, 1.5em);
        cursor: pointer;
    }

    & > [class^="container"] {
        flex-direction: column;
        flex: 1 1 100%;
        transform: translateY(-5vh);
    }

    //- popscreen content
    &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include media('<tablet') {
            @media(max-height: 520px) {
                flex: 1 1 100%;
            }
        }
    }

    &__title {
        margin-block: 0 .5em;
        font-size: var(--popscreen-title-fs, 50px);
        font-weight: var(--popscreen-title-wg, 800);
        text-align: center;
        color: var(--mainColor);

        @include media("<mobile") {
            --popscreen-title-fs: 24px;
        }
        
        @include media(">=mobile", "<tablet") {
            --popscreen-title-fs: 26px;
        }
        
        @include media(">=tablet", "<desktop") {
            @media(min-height: 991px) {
                --popscreen-title-fs: 38px;
            }
        }
        
        @include media(">=desktop") {
            --popscreen-title-fs: 50px;
        }
    
        @include media(">=tablet") {
            @media(max-height: 990px) {
                --popscreen-title-fs: 35px;
            }
        }

        @include media(">=tablet") {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        span {
            display: block;
            font-size: var(--popscreen-titleInc-fs, 1.95em);
            line-height: 1;
            color: var(--secondaryColor);
        }
    }

    &__image-wrapper {
        display: flex;
        justify-content: center;
        flex: 1 1 100%;

        picture {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    &__image {
        max-width: var(--popscreen-secondImg-w, 60vw);

        @include media('<mobile-mid') {
            width: auto;
            max-height: 100%;
        }
        
        @include media('>=mobile-mid') {
            --popscreen-secondImg-w: 240px;
            
            object-fit: cover;
        }
        
        @include media('>=tablet'){
            @media(max-height: 990px) {
                max-height: 35vh;
                width: auto;
                height: 100%;
            }            
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        @include media("<tablet") {
            padding-block: 1.25em;
        }

        @include media(">=tablet") {
            @media(max-height: 990px) {
                padding-block: 1.25em;
            }            
            
            @media(min-height: 991px) {
                padding-block: 2.5em;
            }
        }
    }
}
/*!
 * #END sc_popscreen
 */