section {
    padding-top: map-get($section-inner, mobile); padding-bottom: map-get($section-inner, mobile);

    &.full-bg {
        padding-top: map-get($section-inner, mobile) + .5rem; padding-bottom: map-get($section-inner, mobile) + .5rem;
    }

    & + & {
        margin-top: map-get($section-flow, mobile);
    }

    .container {
        max-width: 480px;
    }
}

footer {
    padding-top: 2 * map-get($section-inner, mobile); padding-bottom: 2 * map-get($section-inner, mobile);
}

img {
    display: block; width: 100%; margin: 0 auto; 
}

body.noscroll {
    margin: 0; height: 100%; overflow: hidden; touch-action: none;
}